import React, { useState, useEffect } from "react";
import ServiceCard from "../serviceCard";
import axios from "axios";
import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css";

export default function RenderServices() {
  const [services, setServices] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchServiceCategories();

    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllServiceCategories"
      );
      setServices(response.data);
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };

  return (
    <div className="">
      <p
        className="font-semibold text-3xl mb-6"
        data-aos="fade-up"
      >
        {t('home.services.renderedServices')}
      </p>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-5 xl:gap-6 w-fit max-sm:">
        {services.map((service) => (
          <div key={service._id} className="p-0" data-aos="fade-up">
            <ServiceCard
              image={service.image}
              type={service.name}
              price={service.price}
              range={service.range}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
