import React from "react";
import Navigation from "../components/nav";
import livingroom from "../assests/images/modern-livingroom.svg";
import { IoMdCheckmark } from "react-icons/io";
import Footer from "../components/home-ui-components/footer";
import one from "../assests/images/01.png";
import two from "../assests/images/02.png";
import three from "../assests/images/03.png";
import four from "../assests/images/04.png";
import { Link,
  //  useNavigate,useLocation 
  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css";

export default function BecomeCleaner() {
  // const location = useLocation();
  // const history = useNavigate();
  // const handleNext =()=>{
  //   history('./cleanerRegForm')
  // }

  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);



  return (
    <div>
      <div>
        <Navigation />
      </div>
      <ToastContainer />
      <div className="flex text-center font-helvetica-neue mt-20 justify-center items-center">
        <div>
          <p className="text-3xl md:text-5xl font-bold" data-aos="fade-up">
            {t('cleaner.title')}
          </p>
          <p
            className="inline-block w-[97%] md:w-[55%] lg:w-[45%] text-[#5B5B5B] text-sm md:text-base mt-5"
            data-aos="fade-up"
          >
            {t('cleaner.subtitle')}
          </p>
        </div>
      </div>
      <div
        className="my-20 w-full flex items-center justify-center"
        data-aos="fade-up"
      >
        <img src={livingroom} alt="" className="w-[90%]" />
      </div>
      <div className="flex justify-center text-[30px]  mb-20">
        <p
          className="text-center w-[98%] md:w-[60%] lg:w-[45%] mt-5 font-bold text-xl md:text-3xl"
          data-aos="fade-up"
        >
          {t('cleaner.reasonTitle')}
        </p>
      </div>
      <div className="flex flex-col gap-y-10 mb-20">
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div
            data-aos="fade-up"
            className="bg-[#EAF4FB] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-r-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5">
                {t('cleaner.fulfilling')}
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.fulfilling1')}
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.fulfilling2')}
                </div>
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-[#EAFBED] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-l-[100px] flex-1 flex flex-col"
          >
            <div className="">
              <p className="text-lg lg:text-2xl font-semibold mb-5 pl-4 lg:pl-0">
                {t('cleaner.competitive')}
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.competitive1')}
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.competitive2')}
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div
            data-aos="fade-up"
            className="bg-[#F0EAFB] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-r-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5">
                {t('cleaner.supportive')}
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.supportive1')}
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.supportive2')}
                </div>
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-[#FBFAEA] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-l-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5 pl-4 lg:pl-0">
                {t('cleaner.training')}
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.training1')}
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.training2')}
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div
            data-aos="fade-up"
            className="bg-[#FBEAEA] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-r-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5">
                {t('cleaner.employee')}
              </p>
              <p className="flex items-center gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.employee1')}
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.employee2')}
                </div>
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-[#FBEAEA] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-l-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5 pl-4 lg:pl-0">
                {t('cleaner.fulfilling')}
              </p>
              <p className="flex items-center gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.fulfilling1')}
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  {t('cleaner.fulfilling2')}
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="text-center mt-4" data-aos="fade-up">
          <Link
            className="bg-primary-sky hover:bg-blue-600 duration-150 text-white py-3 rounded-lg my-10 text-center px-6"
            to="/cleanerRegForm"
          >
            {t('cleaner.applyNow')}
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-y-5 mb-20 mx-20">
        <p
          className="text-center font-bold text-xl lg:text-3xl mb-4"
          data-aos="fade-up"
        >
          {t('cleaner.howItWorks')}
        </p>
        <div className="self-start flex gap-x-10" data-aos="fade-up">
          <img alt="one" src={one} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">{t('cleaner.fill')}</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              {t('cleaner.fillText')}
            </p>
          </div>
        </div>
        <div className="self-end flex gap-x-10 items-center" data-aos="fade-up">
          <img alt="two" src={two} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">{t('cleaner.interview')}</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              {t('cleaner.interviewText')}
            </p>
          </div>
        </div>
        <div
          className="self-start flex gap-x-10 items-center"
          data-aos="fade-up"
        >
          <img alt="three" src={three} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">{t('cleaner.onboarding')}</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              {t('cleaner.onboardingText')}
            </p>
          </div>
        </div>
        <div className="self-end flex gap-x-10 items-center" data-aos="fade-up">
          <img alt="four" src={four} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">{t('cleaner.welcome')}</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              {t('cleaner.welcomeText')}
            </p>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
