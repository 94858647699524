import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomerNavigation from "../../customerNav/nav";
import Navigation from "../../../nav";
import DashboardToggle from "../dashboardToggle";
import Footer from "../../../home-ui-components/footer";
import Referral from "../dashboard-referral/referral";
import Home from "../dashboard-home/home";
import Payment from "../dashboard-payment/payment";
import { useSelector, useDispatch } from "react-redux";
import SaveAddress from "../dashboard-address/saveAddress";
import Schedule from "../../../../pages/schedule";
import OrderHistory from "../dashboard-order-history/OrderHistory";
import axios from "axios";
import cleaning from "../../../../assests/images/dashboardReferral/house-cleaning.svg";
import { controlToggle } from "../../../../redux/features/toggleSlice";
import { useTranslation } from 'react-i18next';
// import MiniLoader from "../../../preloader/mini-preloader";

export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [fullName, setFullName] = useState("");
  const [, setLoading] = useState(true);

  const toggle = useSelector((state) => state.toggle.toggle);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      // toast.error("Please log in to continue.")
    }
  }, [navigate]);

  useEffect(() => {
    fetchCustomer();
  });


  const fetchCustomer = async () => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      axios
        .get(`${apiUrl}/getCustomer/${userId}`)
        .then((response) => {
          const customer = response.data.customer;
          setFullName(customer.name);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching customer data:", error);
          setLoading(false);
        });
    }
  };

// const userId = localStorage.getItem("userId");
//   console.log(userId)
//   // console.log(fullName)

  return (
    <div>
      <div className="bg-[#F2F8FE] w-full min-h-screen">
        <Navigation />
        {/* <CustomerNavigation /> */}
        <div className="2xl:px-48 xl:px-20 lg:px-18 md:px-10 sm:px-6 xs:px-4">
          <div className="mt-10">
            <DashboardToggle />
            <div>
              {toggle === 1 ? (
                <div>
                  <div>
                    <div className="home-multi-bgColor min-h-[250px] rounded-lg shadow-sm my-20 px-5 pt-5  flex justify-between max-md:flex-col max-md:gap-y-10 max-md:items-center">
                      <div className="max-w-[500px] max-md:w-full flex flex-col mr-10 max-md:mr-0 max-md:text-center">
                        <p className="font-semibold mb-3 text-[20px]">
                          {t('dashboard.hello')} {fullName ? `${fullName},` : ""}
                        </p>
                        <p className="max-w-[400px] max-md:mx-auto inline-block text-primary-blurText mb-6 max-md:max-w-[500px]">
                          {t('dashboard.dashboardText')}
                        </p>
                        <div>
                          <button
                            onClick={() => dispatch(controlToggle(2))}
                            className="px-4 py-2 rounded-lg text-base font-medium bg-primary-sky hover:bg-blue-700 duration-150 text-white"
                          >
                            {t('dashboard.placeOrder')}
                          </button>
                        </div>
                      </div>
                      <div>
                        <img src={cleaning} alt="house" />
                      </div>
                    </div>
                  </div>

                  <Home />
                </div>
              ) : toggle === 2 ? (
                <div className="2xl:-mx-48 xl:-mx-20 lg:-mx-18 md:-mx-10 sm:-mx-6 xs:-mx-4 mt-5">
                  <Schedule />
                </div>
              ) : toggle === 3 ? (
                <SaveAddress />
              ) : toggle === 4 ? (
                <Referral />
              ) : toggle === 5 ? (
                <Payment />
              ) : toggle === 6 ? (
                <OrderHistory />
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}