import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chat from "../../assests/images/chat.png";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Contact() {

  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://klean-up-server-hz1y.onrender.com/v1/api/get-in-touch",
        formData
      );
      if (response.data.success) {
        toast.success("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid gap-10 grid-cols-1 md:grid-cols-2 px-4 py-20 items-center">
      <div className='w-full flex justify-start md:justify-end'>
        <div data-aos='fade-right' className="w-[400px] md:w-[450px] lg:w-[550px] pt-10 md:pl-10 flex flex-col rounded-xl lg:bg-gray-100">
        <div className="text-left">
          <p className="text-sm md:text-md font-medium text-gray-600 uppercase">
            {t('home.services.getInTouch')}
          </p>
          <p className="text-lg md:text-3xl font-bold my-6 inline-block w-[80%]">
            {t('home.services.getInTouchText')}
          </p>
          <p className="inline-block w-[95%] md:w-[88%] text-sm md:text-base">
            {t('home.services.contactUsText')}
          </p>

          <div className='w-auto mt-4 pr-8'>
              <Link
                to="https://wa.me/+4915216901919"
                target="_blank"
                // rel="noopener noreferrer"
                className="flex items-center justify-center bg-green-500 text-white font-medium px-6 py-3 w-auto rounded-lg shadow hover:bg-green-600 transition duration-200"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                  alt="WhatsApp Logo"
                  className="w-6 h-6 mr-2"
                />
                {t('home.services.contactWhatsapp')}
              </Link>
          </div>

        </div>
        <div className="ml-auto mt-16 max-lg:hidden">
          <img src={Chat} alt="chat" />
        </div>
      </div>
      </div>

      <div className="w-full" data-aos='fade-left' data-aos-delay='300'>
        <form
          className="flex flex-col w-[98%] md:w-[80%] lg:w-[65%] gap-5 border-black"
          onSubmit={handleSubmit}
        >
          <input
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="border border-gray-200 px-3 py-2 md:py-3 bg-gray-100 rounded-lg focus:ring-0 focus:outline-none"
          />
          <input
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="border border-gray-200 px-3 py-2 md:py-3 bg-gray-100 rounded-lg focus:ring-0 focus:outline-none"
          />
          <input
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            className="border border-gray-200 px-3 py-2 md:py-3 bg-gray-100 rounded-lg focus:ring-0 focus:outline-none"
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            className="border border-gray-200 px-3 py-2 md:py-3 bg-gray-100 rounded-lg focus:ring-0 focus:outline-none"
            rows={5}
          ></textarea>
          <button
            type="submit"
            className="bg-primary-sky hover:bg-blue-700 duration-150 text-white py-2.5 md:py-3 font-medium rounded-lg text-sm md:text-base"
          >
            {loading ? "...Submitting..." : "Submit now"}{" "}
            {/* Display loader if loading */}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
