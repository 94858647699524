import { valuesArr } from "../constants/value";
import ValueCard from "./valueCard";
import { useTranslation } from "react-i18next";

export default function RenderValue() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center pt-20 pb-32  font-helvetica-neue px-4">
      <p className="text-white text-[34px] font-medium">
        {t('about.advantages')}
      </p>
      <div className="grid md:grid-cols-3 xs:grid-cols-1 pt-20 gap-x-10 gap-y-8">
        {valuesArr.map((value, index) => {
          return (
            <ValueCard
              key={index}
              image={value.imgSrc}
              value={t(value.valueKey)}
              desc={t(value.descKey)}
            />
          );
        })}
      </div>
    </div>
  );
}
