import Navigation from "../components/nav";
import WomanCleaner from "../assests/images/woman-cleaning.png";
import RenderValue from "../components/renderValue";
import ExpBg from "../assests/images/experience-bg.png";
import Footer from "../components/home-ui-components/footer";
import Group from "../assests/images/group.png";
import { useNavigate } from "react-router-dom";
import MiniGroup from "../assests/images/miniGroup.png";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Mission() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGetStarted = async()=>{
    const token = sessionStorage.getItem("token");
    if(!token){
      navigate('/login')
    }
    navigate('/dashboard')
  }


  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="overflow-hidden">
      <div>
        <Navigation />
      </div>

      <div
        className="h-[400px]"
        style={{
          backgroundImage: ` url(${WomanCleaner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="mission-container h-[400px] flex  font-helvetica-neue text-white lg:px-52 sm:px-16 items-center xs:px-4">
          <div className="flex flex-col">
            <button
              className="px-3 py-1 bg-gray-800 rounded-full mb-2 block w-fit"
              data-aos="fade-up"
            >
              {t('about.title')}
            </button>

            <p
              className="text-2xl font-semibold inline-block max-w-[400px] mt-4 mb-2"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {t('about.subtitle')}
            </p>
            <p
              className="text-sm md:text-base max-w-[500px]"
              style={{ color: "#bbbbbb" }}
              data-aos="fade-up"
              data-aos-delay="500"
            >
              {t('about.heroText')}
            </p>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row xs:flex-col h-[340px] lg:px-52 md:px-16 sm:px-16 xs:px-4 max-md:mt-10 mb-24">
        <div className="w-1/2 flex items-center max-md:justify-center max-md:w-full">
          <p
            className="text-base md:text-3xl font-semibold"
            data-aos="fade-right"
          >
            {t('about.mission')}
          </p>
        </div>
        <div
          data-aos="fade-up"
          className="w-1/2 flex flex-col justify-center md:mt-28 xs:mt-6  md:max-w-[400px] text-base max-md:items-center max-md:w-full"
          style={{ color: "#333333" }}
        >
          <p className="mb-5 text-sm md:text-base">
            {t('about.missionP1')}
          </p>
          <p className="text-sm md:text-base">
            {t('about.missionP2')}
          </p>
        </div>
      </div>
      <div className="bg-[#F4FBFD] py-16 flex justify-center px-4">
        <div className="max-w-[900px]">
          <p
            className="text-base md:text-2xl font-medium mb-10"
            data-aos="fade-up"
          >
            {t('about.title')}
          </p>
          <p
            className="leading-[28px] text-sm md:text-[17px] font-normal text-gray-700"
            data-aos="fade-up"
          >
            {t('about.aboutP1')}
          </p>

          <p
            className="leading-[28px] text-sm md:text-[17px] font-normal text-gray-700 mt-4"
            data-aos="fade-up"
          >
            {t('about.aboutP2')}
          </p>

          <p
            className="leading-[28px] text-sm md:text-[17px] font-normal text-gray-700 mt-3"
            data-aos="fade-up"
          >
            {t('about.aboutP3')}
          </p>
        </div>
      </div>
      <div
        className="h-[1000px] mb-32 max-md:hidden"
        style={{
          backgroundImage: `url(${Group})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      {/* <div
        className="h-[1000px] mb-32 md:hidden"
        style={{
          backgroundImage: `url(${MiniGroup})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div> */}
      {/* <div className="flex gap-x-10">
          <img src={WomanMop} alt="" className="h-[600px]" />
          <img src={ManClean} alt="" className=" h-[600px]" />
        </div>
        <div className="flex gap-10">
          <img src={HalfLeft} alt="" className=" h-[400px] w-full" />
          <img src={ManTools} alt="" className=" w-full h-[400px]" />
          <img src={HalfRight} alt="" className="h-[400px] w-full" />
        </div> */}

      {/* <div className="grid grid-cols-2 gap-4 w-full my-20"> */}

      {/* <div className="col-span-2 md:col-span-1">
          <img src={WomanMop} alt="" className="h-[600px] w-[900px]" />
        </div>
        <div className="col-span-2 md:col-span-1">
          <img src={ManClean} alt="" className=" h-[600px] w-[1000px]" />
        </div>
        <div className="col-span-2 md:col-span-2 grid grid-cols-3 gap-4">
          <div>
            <img src={HalfLeft} alt="" className=" h-[400px] w-full" />
          </div>
          <div>
            <img src={ManTools} alt="" className=" w-full h-[400px]" />
          </div>
          <div>
            <img src={HalfRight} alt="" className="h-[400px] w-full" />
          </div>
        </div> */}
      {/* </div> */}
      <div className="bg-black" data-aos="fade-up">
        <RenderValue />
      </div>
      {/* <div className="py-20">
        <Partners />
      </div> */}
      <div
        style={{
          backgroundImage: `url(${ExpBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-[550px] px-4 text-center flex flex-col gap-y-10 justify-center items-center"
      >
        <p
          className="text-white text-3xl font-medium max-w-[400px]"
          data-aos="fade-up"
        >
          {t('about.experience')}
        </p>
        <p
          className="text-gray-400 max-w-[500px]"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {t('about.experienceText')}
        </p>
        <button
          className="bg-blue-500 hover:bg-blue-600 duration-150 rounded-lg px-10 py-2.5 text-sm md:text-base text-white"
          onClick={handleGetStarted}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {t('utility.getStarted')}
        </button>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
