import React, { useState } from "react";
import Regular from "../assests/images/regular.png";
import Office from "../assests/images/office.png";
import Windows from "../assests/images/window.png";
import Dry from "../assests/images/dry.png";
import Renovation from "../assests/images/renovation.png";
import Kitchen from "../assests/images/kitchen.png";
import ServiceCard from "./serviceCard";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { useTranslation } from "react-i18next";

const servicesArr = [
  {
    imgSrc: Regular,
    type: "Regular Cleaning",
  },
  {
    imgSrc: Windows,
    type: "Windows Cleaning",
  },
  {
    imgSrc: Kitchen,
    type: "Kitchen Cleaning",
  },
  {
    imgSrc: Dry,
    type: "Dry Cleaning",
  },
  {
    imgSrc: Renovation,
    type: "Renovation",
  },
  {
    imgSrc: Office,
    type: "Office Cleaning",
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();

  const nextSlide = () => {
    console.log("Pressed");
    setCurrentIndex((prevIndex) =>
      prevIndex === servicesArr.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? servicesArr.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative mt-10 overflow-hidden">
      <div className="flex items-center justify-between mb-16">
        <p className="font-semibold text-2xl font-helvetica-neue">
          {t('t.services.renderedServices')}
        </p>
        <div className="flex gap-x-10">
          <button
            onClick={prevSlide}
            className="p-1 bg-gray-200 rounded-full"
          >
            <GoChevronLeft size={30} />
          </button>
          <button
            onClick={nextSlide}
            className="p-1 bg-gray-200 rounded-full"
          >
            <GoChevronRight size={30} />
          </button>
        </div>
      </div>
      <div
        className={`flex sm:w-[450px] transition-transform ease-in-out duration-500 transform translate-x-${
          currentIndex * 100
        }%`}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {servicesArr.map((service, index) => (
          <div key={index} className="flex-none w-full">
            <ServiceCard image={service.imgSrc} type={service.type} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
