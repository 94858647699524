import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Navigation from "../../components/nav";
import Individual from "../../assests/user-profile.png";
import Footer from "../../components/home-ui-components/footer";
import { loadStripe } from "@stripe/stripe-js";

import Toilet from '../../assests/toilet.png';
import Bed from '../../assests/bed.png';
import Window from '../../assests/window.png';
import Bathtub from '../../assests/bathtub.png';

import { toast, ToastContainer } from "react-toastify";
import { FaBullseye } from "react-icons/fa";

import MiniLoader from "../../components/preloader/mini-preloader";

const Modal = ({ isOpen, onClose, title, message, onAction, actionLabel }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg w-1/3">
        <h2 className="text-lg font-bold mb-4">{title}</h2>
        <p className="mb-4">{message}</p>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={onAction}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {actionLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default function PricingDetails() {
  const { slug } = useParams(); // Get the slug from the URL
  const [pricingDetail, setPricingDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bookLoading, setBookLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");

  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  const token = localStorage.getItem("token");
  // console.log(token)

  // Function to fetch pricing data by slug
  const fetchPricingDetail = async (slug) => {
    try {
      // Fetch all pricing data from endpoints
      const [oneTime, onceWeek, twiceMonth, everyMonth, others] =
        await Promise.all([
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/oneTime"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/OnceAweek"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/TwiceAmonth"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/EveryMonth"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/others"
          ),
        ]);

      // Combine all pricing data into one array
      const allPricings = [
        ...oneTime.data,
        ...onceWeek.data,
        ...twiceMonth.data,
        ...everyMonth.data,
        ...others.data,
      ];

      // Find the pricing detail that matches the slug
      const detail = allPricings.find(
        (item) => item.priceName.toLowerCase().replace(/\s+/g, "-") === slug
      );

      if (detail) {
        setPricingDetail(detail);
      } else {
        setError("Pricing details not found.");
      }
    } catch (err) {
      console.error("Error fetching pricing details:", err);
      setError("Error fetching pricing details.");
    } finally {
      setLoading(false);
    }
  };

  const handleBooking = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setBookLoading(true); // Set loading state to true

    try {
      // Step 1: Validation for missing selections
      if (!selectedServices.length || !selectedDuration || !selectedDate) {
        toast.error("Please complete all selections before booking.");
        setBookLoading(false); // Reset loading state
        return; // Exit the function early
      }

      // Step 2: Check for token in localStorage
      const token = localStorage.getItem("token");

      if (!token) {
        setIsModalOpen(true); // Open login modal
        setBookLoading(false); // Reset loading state
        return; // Exit the function early
      }

      // Step 3: Placeholder for payment (to be implemented later)
      console.log("Token exists. Proceed to payment.");
      proceedToPayment();
    } catch (error) {
      console.error("Error during booking:", error);
      toast.error("There was an issue with your booking. Please try again.");
    } finally {
      setBookLoading(false); // Ensure loading is stopped in all cases
    }
  };


  //  pk_test_TYooMQauvdEDq54NiTphI7jx

  const proceedToPayment = async () => {
  setPayLoading(true);
  const token = localStorage.getItem("token");

  if (!token) {
    toast.error("You must be logged in to proceed with payment.");
    setPayLoading(false);
    return;
  }

  // Prepare the order data
  const orderData = {
    streetName,
    city,
    postCode,
    houseNumber,
    building,
    floor,
    name,
    contact,
    email,
    additionalInfo,
    selectedServices,
    selectedDuration,
    selectedDate,
    priceAmount: pricingDetail.priceAmount,
  };

  // Debug log to check what we're sending
  console.log("Order Data being sent:", orderData);
  console.log("Token being used:", token);

  const orderHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Step 1: Create order on the backend
    const orderResponse = await fetch(
      "https://klean-up-server-hz1y.onrender.com/v1/api/orders/",
      {
        method: "POST",
        headers: orderHeaders,
        body: JSON.stringify(orderData),
      }
    );

    // Log the full response for debugging
    console.log("Order Response Status:", orderResponse.status);
    const responseData = await orderResponse.json();
    console.log("Order Response Data:", responseData);

    if (!orderResponse.ok) {
      throw new Error(responseData.message || "Order creation failed.");
    }

    const orderId = responseData._id;
    console.log("Order created successfully with ID:", orderId);

    // Step 2: Initialize Stripe
    const stripe = await loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
    if (!stripe) {
      throw new Error("Failed to initialize Stripe");
    }

    // Step 3: Create Stripe checkout session
    const checkoutResponse = await fetch(
      "https://klean-up-server-hz1y.onrender.com/v1/api/checkoutStripe",
      {
        method: "POST",
        headers: orderHeaders,
        body: JSON.stringify({
          orderId,
          amount: pricingDetail.priceAmount,
          currency: "eur",
          services: selectedServices,
          customerEmail: email,
        }),
      }
    );

    if (!checkoutResponse.ok) {
      const errorData = await checkoutResponse.json();
      console.log("Checkout Error:", errorData);
      throw new Error(errorData.message || "Failed to create checkout session");
    }

    const session = await checkoutResponse.json();
    console.log("Stripe session created:", session);

    // Step 4: Redirect to Stripe checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.log("Stripe redirect error:", error);
      throw new Error(error.message);
    }
  } catch (error) {
    console.error("Payment process error:", error);
    toast.error(error.message || "An error occurred during payment processing");
  } finally {
    setPayLoading(false);
  }
};





  const handleLoginRedirect = () => {
    // Open login in a new tab
    window.open("/login", "_blank");
  };

  useEffect(() => {
    fetchPricingDetail(slug);
  }, [slug]);

  if (loading) {
    return (
      <>
        <MiniLoader />
      </>
    );
  };
  if (error) return <p>{error}</p>;

  // Services

  // Mock data for services
  const services = [
    {
      id: 1,
      name: "Bathroom Cleaning",
      price: 12,
      discount: "14% Off",
      image: Bathtub,
    },
    {
      id: 2,
      name: "Bedroom Cleaning",
      price: 12,
      discount: "14% Off",
      image: Bed,
    },
    {
      id: 3,
      name: "Window Cleaning",
      price: 12,
      discount: "14% Off",
      image: Window,
    },
    {
      id: 4,
      name: "Toilet Cleaning",
      price: 12,
      discount: "14% Off",
      image: Toilet,
    },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return "Not selected";

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Add suffix to day
    const daySuffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    return `${day}${daySuffix} ${month}, ${year}`;
  };

  // Handle checkbox toggle
  const handleSelect = (service) => {
    setSelectedServices((prev) => {
      const isSelected = prev.some((selected) => selected.id === service.id);

      return isSelected
        ? prev.filter((selected) => selected.id !== service.id)
        : [...prev, service];
    });
  };

  // const stripe = await loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

  const handleRemoveService = (serviceId) => {
    setSelectedServices((prev) =>
      prev.filter((service) => service.id !== serviceId)
    );
  };

  return (
    <>
      <div className="bg-white">
        <Navigation />
      </div>
      <ToastContainer />
      <section className="bg-blue-600 px-6 md:px-12 py-16 text-center">
        <h1 className="text-2xl md:text-3xl lg:text-4xl text-white font-medium">
          {pricingDetail.priceName}
        </h1>
        <p className="text-sm md:text-lg mt-2 text-gray-100 font-medium">
          €{pricingDetail.priceAmount}/ Hour
        </p>

        <div className="text-base font-normal mt-8 text-white">
          {pricingDetail.priceItems.map((item, index) => (
            <p key={index} className="my-2">
              {item.name}: €{item.price}
            </p>
          ))}
        </div>
      </section>
      {/* <div className="p-6">
        <div className="flex flex-col items-center">
          <img
            src={pricingDetail.priceImage}
            alt={pricingDetail.priceName}
            className="w-[120px] h-[120px] mb-4"
          />
          <h1 className="text-2xl font-bold mb-2">{pricingDetail.priceName}</h1>
          <p className="text-xl font-semibold mb-4">
            €{pricingDetail.priceAmount}{" "}
            <span className="text-sm font-normal">/ Hour</span>
          </p>
          <div className="text-base font-normal">
            {pricingDetail.priceItems.map((item, index) => (
              <p key={index} className="my-2">
                {item.name}: ${item.price}
              </p>
            ))}
          </div>
        </div>
      </div> */}

      <div className="grid gap-4 grid-cols-1 md:grid-cols-[1fr,28rem] items-start mt-10 px-4 md:px-8 lg:px-16 h-full relative">
        <div className="mb-4">
          <div className="px-4">
            <div className="border border-blue-600 border-2 bg-blue-50 rounded-lg text-center w-[250px] pt-3 pb-8">
              <img src={Individual} alt="" className="inline-block" />

              <p className="mt-3 font-medium text-base">Individual</p>
            </div>
          </div>

          <div className="px-4 mt-6">
            <h1 className="text-xl font-medium">Select services</h1>
            <div className="grid grid-cols-2 gap-6 py-4">
              {services.map((service) => (
                <div
                  key={service.id}
                  className="relative bg-white rounded-lg border border-blue-600 py-8 flex flex-col items-center overflow-hidden"
                >
                  {/* Discount label */}
                  <span className="absolute top-0 left-0 bg-blue-500 text-white text-xs font-semibold py-1 px-2 rounded-br-lg">
                    {service.discount}
                  </span>

                  {/* Icon placeholder */}
                  <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center mb-4">
                    <span className="text-lg font-bold text-gray-500">
                      <img src={service.image} alt="image" />
                    </span>
                  </div>

                  <h3 className="text-lg font-medium text-gray-800 mb-2">
                    {service.name}
                  </h3>
                  <p className="text-sm text-gray-600">{service.price} Euros</p>

                  <label className="flex items-center mt-4 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedServices.some(
                        (selected) => selected.id === service.id
                      )}
                      onChange={() => handleSelect(service)}
                      className="hidden"
                    />
                    <span
                      className={`w-5 h-5 border-2 ${
                        selectedServices.some(
                          (selected) => selected.id === service.id
                        )
                          ? "bg-blue-500 border-blue-500"
                          : "border-gray-400"
                      } rounded-md flex items-center justify-center`}
                    >
                      {selectedServices.some(
                        (selected) => selected.id === service.id
                      ) && <span className="text-white font-bold">✔</span>}
                    </span>
                    <span className="ml-2 text-sm text-gray-700">
                      {service.name}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="px-4 mt-6">
            <h1 className="text-xl font-medium">Package duration</h1>

            <div className="input-group mt-4">
              <label>Select duration</label>
              <select
                value={selectedDuration}
                onChange={(e) => setSelectedDuration(e.target.value)}
                className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
              >
                <option value="">Select a duration</option>
                <option value="one-time">One time</option>
                <option value="once-a-week">Once a week</option>
                <option value="twice-a-month">Twice a month</option>
                <option value="every-month">Every month</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>

          <div className="px-4 mt-16">
            <h1 className="text-xl font-medium">
              Pick a suitable date and time <br /> for your cleaning
            </h1>
            <p className="text-md font-medium text-gray-600 my-3">
              Your cleaner comes fully equipped with all the necessary <br />{" "}
              cleaning tools and solutions
            </p>

            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="input-group">
                <label>Select date</label>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group">
                <label>Select time</label>
                <input
                  type="time"
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>
            </div>
          </div>

          <div className="px-4 mt-16">
            <h1 className="text-xl font-medium">
              Input your address and <br /> contact details
            </h1>

            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="input-group">
                <label>Street name</label>
                <input
                  type="text"
                  value={streetName}
                  onChange={(e) => setStreetName(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group">
                <label>City</label>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group">
                <label>Post code</label>
                <input
                  type="text"
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group">
                <label>House number</label>
                <input
                  type="text"
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group">
                <label>Building</label>
                <input
                  type="text"
                  value={building}
                  onChange={(e) => setBuilding(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group">
                <label>Floor</label>
                <input
                  type="text"
                  value={floor}
                  onChange={(e) => setFloor(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>
            </div>

            <hr className="my-5 border border-blue-600 border-2" />

            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="input-group">
                <label>Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group">
                <label>Contact</label>
                <input
                  type="text"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>
            </div>

            <div className="space-y-3">
              <div className="input-group mt-3">
                <label>Email address</label>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="input-group mt-3">
                <label>Additional information</label>
                <textarea
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                  className="w-full px-3 py-2.5 h-[150px] border border-gray-300 rounded-lg"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 bg-white md:border rounded-lg inline-block mb-4 md:sticky md:top-4">
          <div>
            <hr className="md:hidden mb-10" />
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <p className="text-gray-600 text-sm font-medium">Package</p>
                <p className="text-sm font-medium capitalize">
                  {selectedDuration || "Not selected"}{" "}
                  {selectedDuration && "cleaning"}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-gray-600 text-sm font-medium">
                  Scheduled date
                </p>
                <p className="text-sm font-medium">
                  {formatDate(selectedDate) || "Not selected"}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-gray-600 text-sm font-medium">
                  Cleaning equipments
                </p>
                <p className="text-sm font-medium">Provided by the cleaner</p>
              </div>
            </div>

            <hr className="my-3" />

            <h2 className="text-sm font-bold mb-4">Selected Services</h2>
            <div className="flex flex-wrap gap-2">
              {selectedServices.length > 0 ? (
                selectedServices.map((service) => (
                  <div
                    key={service.id}
                    className="flex items-center bg-blue-100 text-blue-700 rounded-full px-3 py-1 text-sm font-medium"
                  >
                    {service.name}
                    <button
                      onClick={() => handleRemoveService(service.id)}
                      className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      ✕
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No services selected</p>
              )}
            </div>

            <hr className="my-3" />

            <div className="flex items-center space-x-3">
              <input
                type="text"
                className="px-3 py-2.5 text-sm border border-gray-300 rounded-lg flex-1"
                placeholder="Bonus"
              />
              <button className="text-white text-sm px-3 py-2.5 bg-blue-600 hover:bg-blue-700 duration-150 rounded-lg">
                Apply
              </button>
            </div>

            <hr className="my-3" />

            <div className="bg-gray-50 px-2 py-3 rounded-lg">
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <p className="text-gray-600 text-sm font-medium">Price</p>
                  <p className="text-sm font-medium">
                    €{pricingDetail.priceAmount}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <button
                onClick={handleBooking}
                disabled={bookLoading}
                className={`text-white w-full text-sm px-3 py-2.5 font-medium rounded-lg 
                ${
                  bookLoading
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-700 duration-150"
                }`}
              >
                {bookLoading ? "Loading..." : "Book Cleaning"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Log in Required"
        message="You need to be logged in to book a cleaning. Please log in or sign up in a new tab, and then try right again."
        onAction={handleLoginRedirect}
        actionLabel="Go to Login"
      />

      <div className="">
        <Footer />
      </div>
    </>
  );
}
