import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsArrowLeft } from "react-icons/bs";
import location from "../../../../assests/images/dashboardAddress/web-address 1.svg";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../../../redux/features/toggleSlice";
import MiniLoader from "../../../preloader/mini-preloader";
import { toast } from "react-toastify";

import { useTranslation } from 'react-i18next'

const AddAddressModal = ({ isOpen, closeModal }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const customerId = sessionStorage.getItem("userId");

  const {t } = useTranslation();

  // console.log(customerId)

  const token = sessionStorage.getItem("token");

  const [formData, setFormData] = useState({
    streetName: "",
    city: "",
    postCode: "",
    houseNumber: "",
    building: "",
    entranceNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Form Data:", formData);
      console.log("Authorization Token:", token);

      const response = await fetch(
        `https://klean-up-server-hz1y.onrender.com/v1/api/customerAddress/${customerId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success("Address added successfully!");
        closeModal();
      } else {
        const errorData = await response.json();
        console.error("Error response:", errorData);
        toast.error(errorData.message || "Error submitting address");
      }
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("An error occurred. Please try again.");
    }
  };


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
      <div className="bg-white rounded-xl p-5 w-[95%] md:w-[55%] lg:w-[45%]">
        <h2 className="text-lg font-bold mb-4">Add New Address</h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            name="streetName"
            value={formData.streetName}
            onChange={handleChange}
            placeholder="Street Name"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="postCode"
            value={formData.postCode}
            onChange={handleChange}
            placeholder="Post Code"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="houseNumber"
            value={formData.houseNumber}
            onChange={handleChange}
            placeholder="House Number"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="building"
            value={formData.building}
            onChange={handleChange}
            placeholder="Building"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="entranceNumber"
            value={formData.entranceNumber}
            onChange={handleChange}
            placeholder="Floor Number"
            className="border px-4 py-2 rounded-md"
            required
          />
          <div className="flex justify-between mt-4">
            <button
              type="button"
              onClick={closeModal}
              className="bg-gray-300 py-2 px-6 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#3DA5EC] py-2 px-6 rounded-lg text-white"
            >
              Add Address
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EditAddressModal = ({ isEditOpen, closeEditModal, address }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const customerId = sessionStorage.getItem("userId");
  // const token = sessionStorage.getItem("token");

  const [formData, setFormData] = useState({
    streetName: "",
    city: "",
    postCode: "",
    houseNumber: "",
    building: "",
    entranceNumber: "",
    addressId: address._id
  });

  // console.log(address._id)

  useEffect(() => {
    if (address) {
      setFormData({
        streetName: address.streetName || "",
        city: address.city || "",
        postCode: address.postCode || "",
        houseNumber: address.houseNumber || "",
        building: address.building || "",
        entranceNumber: address.entranceNumber || "",
        addressId: address._id,
      });
    }
  }, [address]);

  const [addresses, setAddresses] = useState(null);

  // Handle input change
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(
        `${apiUrl}/updateAddressInformation/${customerId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        console.log("Address updated successfully");
        toast.success("Address updated successfully.");
        closeEditModal();
      } else {
        console.error("Error updating address");
        toast.error("Error updating address. Please try again later");
        console.log(formData);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response);
      }
      console.error("Error updating address:", error.message || error);
      alert(`Error updating address: ${error.message || "Unknown error"}`);
    }
  };

  if (!isEditOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
      <div className="bg-white rounded-xl p-5 w-[95%] md:w-[55%] lg:w-[45%]">
        <h2 className="text-lg font-bold mb-4">Edit Address</h2>
        <form onSubmit={handleSave} className="flex flex-col gap-4">
          <input
            type="text"
            name="streetName"
            value={formData.streetName}
            onChange={handleChange}
            placeholder="Street Name"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="postCode"
            value={formData.postCode}
            onChange={handleChange}
            placeholder="Post Code"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="houseNumber"
            value={formData.houseNumber}
            onChange={handleChange}
            placeholder="House Number"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="building"
            value={formData.building}
            onChange={handleChange}
            placeholder="Building"
            className="border px-4 py-2 rounded-md"
            required
          />
          <input
            type="text"
            name="entranceNumber"
            value={formData.entranceNumber}
            onChange={handleChange}
            placeholder="Floor Number"
            className="border px-4 py-2 rounded-md"
            required
          />
          <div className="flex justify-between mt-4">
            <button
              type="button"
              onClick={closeEditModal}
              className="bg-gray-300 py-2 px-6 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 duration-150 py-2 px-6 rounded-lg text-white"
            >
             Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default function SaveAddress() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const token = sessionStorage.getItem("token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const customerId = sessionStorage.getItem("userId");

  const { t } = useTranslation();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openEditModal = (address) => {
    setSelectedAddress(address);
    setIsEditModalOpen(true);
  }
  const closeEditModal = () => {
    setIsEditModalOpen(false)
  }

  const fetchAddresses = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/getAllAddressInformation/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setAddresses(data.AddressInformation);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleDelete = async (addressId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${apiUrl}/deleteAddressInformation/${customerId}/${addressId}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ addressId }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to delete address: ${response.statusText}`);
      }

      const result = await response.json();
      toast.success("Address deleted successfully!");
      fetchAddresses();
      // Optionally, update the UI (e.g., refresh the list of addresses)
      console.log(result);
    } catch (error) {
      console.error("Error deleting address:", error);
      alert("Failed to delete the address. Please try again.");
    }
  };

  return (
    <>
      <div className="container mx-auto my-8 p-4">
        {addresses.length === 0 ? (
          <div className="flex mx-2 justify-center items-center rounded-xl px-5 mt-8 mb-20 bg-white shadow-sm py-[50px]">
            <div className="flex flex-col gap-7 items-center">
              <img src={location} alt="location" className="w-[150px]" />
              <p className="text-[36px] font-bold max-sm:text-[24px]">
                No new addresses yet
              </p>
              <button
                onClick={openModal}
                className="w-fit bg-[#3DA5EC] py-3 px-10 rounded-lg text-white"
              >
                {t('dashboard.addAddress')}
              </button>
            </div>

            {/* Modal */}
            <AddAddressModal isOpen={isModalOpen} closeModal={closeModal} />
          </div>
        ) : (
          <div className="bg-white px-6 py-8 rounded-lg">
            <div className="flex items-center justify-between my-3">
                <h1 className="text-xl font-bold mb-4">{t('dashboard.addressInformation')}</h1>
              <button
                onClick={openModal}
                className="font-medium bg-blue-600 hover:bg-blue-700 py-2 px-4 rounded-lg text-white"
              >
                  {t('dashboard.addAddress')}
              </button>
            </div>
            <table className="min-w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2 text-left">
                      {t('dashboard.streetName')}
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                      {t('dashboard.city')}
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                      {t('dashboard.postCode')}
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                      {t('dashboard.houseNumber')}
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                      {t('dashboard.building')}
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                      {t('dashboard.floorNumber')}
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                      {t('dashboard.actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {addresses.map((address) => (
                  <tr key={address._id} className="hover:bg-gray-50">
                    <td className="border border-gray-300 px-4 py-2">
                      {address.streetName}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {address.city}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {address.postCode}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {address.houseNumber}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {address.building}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {address.entranceNumber}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <div className="flex gap-2">
                        <button
                          onClick={() => openEditModal(address)}
                          className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(address._id)}
                          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Modal */}
            <AddAddressModal isOpen={isModalOpen} closeModal={closeModal} />

            {isEditModalOpen && (
              <EditAddressModal
                isEditOpen={isEditModalOpen}
                closeEditModal={closeEditModal}
                address={selectedAddress}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
