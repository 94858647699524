import React, { useState, useEffect } from "react";
import axios from "axios";
// import { GoChevronDown } from "react-icons/go";
// import { RxChevronUp } from "react-icons/rx";
import { Link } from "react-router-dom";
// import Girl from "../../../assests/images/girl.png";
import { GrNotification } from "react-icons/gr";
import { MenuButton } from "./menu";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTokenStatus from "../../hooks/useTokenStatus";
import logo from "../../../assests/images/FreshMClean-logo PNG 2.png";

export default function CustomerNavigation({ onAuthPage, onSignUpPage }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { tokenAvailable } = useTokenStatus();

  const apiUrl = process.env.REACT_APP_API_URL;
  const [, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [customer, setCustomer] = useState("");
  
  useEffect(() => {
    const fetchCustomerData = async () => {
      const customerId = sessionStorage.getItem("userId");
      try {
        const response = await axios.get(`${apiUrl}/getCustomer/${customerId}`);
        setCustomer(response.data.customer);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        // toast.error("Failed to fetch customer data");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  const handleOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const handleToggleDropdown = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };
  const history = useNavigate();

  const handleLogout = async () => {
    sessionStorage.clear();
    localStorage.clear();
    await toast.success("You have been logged out!");
    setTimeout(() => {
      history("/login");
    }, 1000);
  };

  return (
    <div className="relative ">
      <nav className="flex justify-between h-20 2xl:px-48 xl:px-20 lg:px-18 md:px-10 sm:px-6 xs:px-4 shadow-sm items-center bg-white">
        <div className="flex gap-5">
          <ToastContainer />
          <Link to="/">
            <img src={logo} alt="freshMclean logo" className="nav-logo-login" />
          </Link>
          <div className="flex gap-5 max-lg:hidden">
            <Link to="/about">
              <div className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                About
              </div>
            </Link>
            {/* <div className="relative">
              <div
                className="flex flex-row gap-2 items-center cursor-pointer hover:text-blue-500 duration-150 hover:px-3 hover:rounded-lg px-3 rounded-lg"
                onClick={handleToggleDropdown}
              > */}
            <Link to="/price">
              <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Pricing
              </p>
            </Link>
            {/* {isDropdownOpen ? (
                  <GoChevronDown color="black" />
                ) : (
                  <RxChevronUp color="black" />
                )}
              </div>
              {isDropdownOpen && (
                <div
                  className={`bg-white absolute z-10 px-4 py-4 space-y-2 w-[200px] ${
                    isDropdownOpen
                      ? "opacity-100"
                      : "opacity-0 pointer-events-none"
                  }`}
                >
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                </div>
              )}
            </div> */}
            <Link to="/review">
              <div className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Reviews
              </div>
            </Link>
            {/* <Link to="/contact">
              <div className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Contact
              </div>
            </Link> */}
            <Link to="/faqs">
              <div className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                FAQS
              </div>
            </Link>
          </div>
        </div>

        <div className="flex justify-center items-center gap-x-3 max-lg:hidden relative">
          {tokenAvailable ? (
            <>
              <img
                src={customer.picture || "https://via.placeholder.com/150"}
                alt="Profile"
                className="w-[40px] h-[40px] rounded-full cursor-pointer object-cover"
                onClick={() => setIsOpen(!isOpen)}
              />

              {isOpen && (
                <div className="absolute right-0 top-12 bg-white border border-gray-300 rounded-lg shadow-lg w-48 z-40">
                  <ul className="py-2 text-sm">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      <Link to="/dashboard/profile" className="w-full block">
                        Profile
                      </Link>
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={handleLogout}
                    >
                      Logout
                    </li>
                  </ul>
                </div>
              )}
            </>
          ) : null}
        </div>

        {/* toggle icon */}

        <div className="lg:hidden flex gap-x-5" onClick={handleOpen}>
          <div className="flex justify-center items-center gap-x-3">
            <GrNotification size={24} />
            {/* <img
              src={Girl}
              alt=""
              className="w-[40px] h-[40px] rounded-full "
            /> */}
          </div>
          <MenuButton onOpen={isMenuOpen} />
        </div>
      </nav>
      {isMenuOpen && (
        <div className=" z-[50] shadow-md bg-white absolute w-full px-5 pb-10">
          {" "}
          <div className="flex flex-col gap-5 ">
            <Link to="/about">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
                About
              </div>
            </Link>
            <Link to="/price">
              <p className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Pricing
              </p>
            </Link>
            <Link to="/review">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
                Reviews
              </div>
            </Link>
            {/* <Link to='/about'>
            <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
              Contact
            </div>
            </Link> */}
            <Link to="/faqs">
              <div className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg w-fit">
                FAQS
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
