import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GoChevronDown } from "react-icons/go";
import { RxCaretDown, RxChevronUp } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import UKFlag from "../assests/images/UnitedKingdom.png";
import axios from "axios";
import useTokenStatus from "./hooks/useTokenStatus";
import logo from "../assests/images/FreshMClean-logo PNG 2.png"
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Navigation() {
  const [icon, setIcon] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { tokenAvailable } = useTokenStatus();
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleIcon = () => {
    setIcon(!icon);
  };

  // New Addition for Dropdown profile photo
  const apiUrl = process.env.REACT_APP_API_URL;
  const [, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [customer, setCustomer] = useState("");

  useEffect(() => {
    const fetchCustomerData = async () => {
      const customerId = sessionStorage.getItem("userId");
      try {
        const response = await axios.get(`${apiUrl}/getCustomer/${customerId}`);
        setCustomer(response.data.customer);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        // toast.error("Failed to fetch customer data");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  const handleOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const history = useNavigate();

  const handleLogout = async () => {
    sessionStorage.clear();
    localStorage.clear();
    await toast.success("You have been logged out!");
    setTimeout(() => {
      history("/login");
    }, 1000);
  };

  return (
    <nav className="bg-white 2xl:px-48 xl:px-20 lg:px-18 md:px-10 sm:px-6 xs:px-4 pt-10 pb-4 shadow-sm z-20 relative">
      {/* <ToastContainer /> */}
      <div className=" flex flex-row justify-between items-center -mt-5 hidden-sidebar">
        <div className="flex flex-row items-center max-w-fit lg:gap-2 xl:gap-4 max-md:gap-3 font-normal cursor-pointer">
          <Link to="/">
            <img src={logo} alt="freshmclean logo" className="nav-logo-update" />
          </Link>

          <Link to="/" className="pl-3">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              {t('navigation.home')}
            </p>
          </Link>
          <Link to="/about">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              {t('navigation.about')}
            </p>
          </Link>
          <Link to="/price">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              {t('navigation.pricing')}
            </p>
          </Link>
          <Link to="/review">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              {t('navigation.reviews')}
            </p>
          </Link>
          <Link to="/faqs">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              {t('navigation.faqs')}
            </p>
          </Link>
          <Link to="/membership">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              {t('navigation.becomeCleaner')}
            </p>
          </Link>
        </div>
        <div className="gap-3 flex items-center">
          <div className="relative">
            <button 
              onClick={handleToggleDropdown}
              className="flex justify-center items-center gap-1 hover:bg-gray-100 p-2 rounded-lg"
            >
              <p className="font-medium text-[16px]">{currentLang.toUpperCase()}</p>
              <img 
                src={currentLang === 'en' ? UKFlag : "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg"} 
                alt="language flag" 
                className="w-6 h-4"
              />
              {isDropdownOpen ? <RxChevronUp size={24} /> : <RxCaretDown size={24} />}
            </button>
            {isDropdownOpen && (
              <div className="absolute top-full right-0 mt-1 bg-white border rounded-lg shadow-lg z-[100] min-w-[150px]">
                <button
                  className={`flex items-center gap-2 w-full px-4 py-2 hover:bg-gray-100 ${
                    currentLang === 'en' ? 'bg-gray-50' : ''
                  }`}
                  onClick={() => {
                    i18n.changeLanguage('en');
                    setCurrentLang('en');
                    setDropdownOpen(false);
                  }}
                >
                  <img src={UKFlag} alt="UK flag" className="w-6 h-4" />
                  <span>English</span>
                </button>
                <button
                  className={`flex items-center gap-2 w-full px-4 py-2 hover:bg-gray-100 ${
                    currentLang === 'de' ? 'bg-gray-50' : ''
                  }`}
                  onClick={() => {
                    i18n.changeLanguage('de');
                    setCurrentLang('de');
                    setDropdownOpen(false);
                  }}
                >
                  <img src="https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg" alt="German flag" className="w-6 h-4" />
                  <span>Deutsch</span>
                </button>
              </div>
            )}
          </div>
          <div>
            {tokenAvailable ? (
              window.location.pathname.includes("/dashboard") ? (
                <>
                  <img
                    src={customer.picture || "https://via.placeholder.com/150"}
                    alt="Profile"
                    className="w-[40px] h-[40px] rounded-full cursor-pointer object-cover"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  {isOpen && (
                    <div className="absolute right-0 top-16 bg-white border border-gray-300 rounded-lg shadow-lg w-48 z-40">
                      <ul className="py-2 text-sm">
                        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                          <Link to="/dashboard/profile" className="w-full block">
                            Profile
                          </Link>
                        </li>
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={handleLogout}
                        >
                          Logout
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <Link to="/dashboard">
                  <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1">
                    Dashboard
                  </button>
                </Link>
              )
            ) : (
              <div className="flex gap-2">
                <Link to="/login">
                  <button className="py-2 px-5 border border-gray-300 rounded-lg font-normal m-0 hover:bg-gray-100 transition duration-300 transform">
                    {t('navigation.login')}
                  </button>
                </Link>
                <Link to="/signup">
                  <button className="py-2 px-5 rounded-lg font-medium text-white bg-blue-600 m-0 text-center hover:bg-blue-700 transition duration-300">
                    {t('navigation.register')}
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* mini nav */}

      <div className="show-hamburger  rounded-md flex -mt-5 font-helvetica-neue items-center justify-between mx-10 max-lg:mx-0">
        <img src={logo} alt="freshmclean logo" className="nav-logo-update" />
        <div className="flex items-center gap-3">
          <div className=" flex items-center">
            <div className="relative">
              <button
                onClick={handleToggleDropdown}
                className="flex justify-center items-center gap-1 hover:bg-gray-100 p-2 rounded-lg"
              >
                <p className="font-medium text-[16px]">{currentLang.toUpperCase()}</p>
                <img
                  src={currentLang === 'en' ? UKFlag : "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg"}
                  alt="language flag"
                  className="w-6 h-4"
                />
                {isDropdownOpen ? <RxChevronUp size={24} /> : <RxCaretDown size={24} />}
              </button>
              {isDropdownOpen && (
                <div className="absolute top-full right-0 mt-1 bg-white border rounded-lg shadow-lg z-[100] min-w-[150px]">
                  <button
                    className={`flex items-center gap-2 w-full px-4 py-2 hover:bg-gray-100 ${currentLang === 'en' ? 'bg-gray-50' : ''
                      }`}
                    onClick={() => {
                      i18n.changeLanguage('en');
                      setCurrentLang('en');
                      setDropdownOpen(false);
                    }}
                  >
                    <img src={UKFlag} alt="UK flag" className="w-6 h-4" />
                    <span>English</span>
                  </button>
                  <button
                    className={`flex items-center gap-2 w-full px-4 py-2 hover:bg-gray-100 ${currentLang === 'de' ? 'bg-gray-50' : ''
                      }`}
                    onClick={() => {
                      i18n.changeLanguage('de');
                      setCurrentLang('de');
                      setDropdownOpen(false);
                    }}
                  >
                    <img src="https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg" alt="German flag" className="w-6 h-4" />
                    <span>Deutsch</span>
                  </button>
                </div>
              )}
            </div>
            <div className="max-[550px]:hidden">
              {tokenAvailable ? (
                window.location.pathname.includes("/dashboard") ? (
                  <>
                    <img
                      src={customer.picture || "https://via.placeholder.com/150"}
                      alt="Profile"
                      className="w-[40px] h-[40px] rounded-full cursor-pointer object-cover"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                    {isOpen && (
                      <div className="absolute right-0 top-16 bg-white border border-gray-300 rounded-lg shadow-lg w-48 z-40">
                        <ul className="py-2 text-sm">
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            <Link to="/dashboard/profile" className="w-full block">
                              Profile
                            </Link>
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={handleLogout}
                          >
                            Logout
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  <Link to="/dashboard">
                    <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1">
                      Dashboard
                    </button>
                  </Link>
                )
              ) : (
                <div className="flex gap-2">
                  <Link to="/login">
                    <button className="py-2 px-5 border border-gray-300 rounded-lg font-normal m-0 hover:bg-gray-100 transition duration-300 transform">
                      {t('navigation.login')}
                    </button>
                  </Link>
                  <Link to="/signup">
                    <button className="py-2 px-5 rounded-lg font-medium text-white bg-blue-600 m-0 text-center hover:bg-blue-700 transition duration-300">
                      {t('navigation.register')}
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="ml-auto" onClick={handleIcon}>
            {icon ? <RxCross1 size={22} /> : <GiHamburgerMenu size={22} />}
          </div>
        </div>
      </div>

      {icon && (
        <div className="absolute left-0 text-base max-lg:font-medium w-full  z-20 shadow-lg bg-white text-green-950 show-hamburger py-10 pl-16 max-md:pl-8 max-xs:pl-4">
          <div className="flex flex-col items-start gap-5 bounce dropdown text-black">
            <Link to="/">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="cursor-pointer">Home</p>
              </div>
            </Link>
            <Link to="/about">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="cursor-pointer">About</p>
              </div>
            </Link>
            <Link to="/price">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="cursor-pointer">Pricing</p>
              </div>
            </Link>
            <Link to="/review">
              <div className="cursor-pointer flex flex-row gap-2 items-center hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="">Reviews</p>
              </div>
            </Link>
            {/* <Link to="/contact">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="">Contacts</p>
              </div>
            </Link> */}

            <Link to="/faqs">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="">FAQS</p>
              </div>
            </Link>
            <Link to="/membership">
              <p className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Become a cleaner
              </p>
            </Link>
          </div>
          <div className="min-[550px]:hidden mt-5">
            {tokenAvailable ? (
              <Link to="/dashboard">
                <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                  Dashboard
                </button>
              </Link>
            ) : (
              <div className="flex gap-2">
                <Link to="/login">
                  <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                    Login
                  </button>
                </Link>
                <Link to="/signup">
                  <button className="py-2 px-5 rounded-xl font-medium text-white bg-primary-sky m-0 text-center hover:bg-primary-skyDark hover:shadow-md hover:-translate-y-1 transition duration-300">
                    Sign Up
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}