import { addAddress } from "../redux/features/toggleSlice";

export default {
  navigation: {
    home: 'Home',
    about: 'About',
    pricing: 'Pricing',
    reviews: 'Reviews',
    schedule: 'Schedule',
    login: 'Login',
    register: 'Register',
    dashboard: 'Dashboard',
    faqs: 'FAQs',
    becomeCleaner: 'Become a Cleaner'
  },
  utility: {
    createAccount: "Create Account",
    openYourDashboard: "Open your dashboard",
    benefits: "Benefits",
    bookACleaning: "Book a cleaning",
    subscribe: "Subscribe",
    getStarted: "Get started",
  },
  home: {
    hero: {
      title: 'House cleaning services at your ',
      subtitle: 'Professional Cleaning Services for Your Home',
      cta: 'Get Started',
      proceedToDashboard: 'Proceed to dashboard'
    },
    services: {
      renderedServices: "Rendered Services",
      weAre: "We are ",
      experienceText: "Experience cleaning services tailored to your needs and your schedule. We bring the shine, you enjoy the comfort.",
      howItWorks: "How it works",
      innerWorking: "The Inner Working of Our Service!",
      cleanText: "We understand that a clean and well-maintained environment is not just a preference but a necessity",
      bookYourCleaning: "Book Your Cleaning",
      chooseBooking: "Choose your cleaning type, frequency, and preferred date and time.",
      pricing: "Pricings",
      pricingText: "Our pricing structure is transparent and competitive. Choose from a variety of packages or customize to fit your needs.",
      testimonials: "Testimonials",
      testimonialsText: "We believe that the true measure of our success lies in the experiences and voices of our valued customers.",
      getInTouch: "Get in touch",
      getInTouchText: "We are here to help you with your cleaning",
      contactUsText: "Whether you're moving in or out, our elite cleaning service prepares your home with a meticulous clean that meets leasing standards.",
      contactWhatsapp: "Contact us on Whatsapp",
      partners: "Partners",
      footerText: "Our team of experienced and reliable cleaners is dedicated to providing top-notch cleaning services for your home.",
    },

  },
  about: {
    title: 'About Us',
    subtitle: "FreshMclean House Cleaning services at your fingertip",
    mission: 'Our Mission',
    missionP1: "Our mission is simple: to provide top-notch cleaning solutions that exceed expectations while ensuring customer satisfaction.",
    missionP2: "We strive to create pristine environments, contributing to your comfort and well-being.",
    heroText: "We're dedicated to delivering exceptional cleaning services tailored to meet your needs.With years of experience and a team of skilled professionals, we take pride in creating clean and healthy spaces for our clients.",
    aboutP1: "At FreshMclean, we value professionalism, reliability, and convenience. We meet customer expectations, offer flexible services, and prioritize long-term cooperation. Our services are available anytime, even early mornings or late evenings, with transparent cleaning times to help you plan efficiently.",
    aboutP2: "We act quickly—arriving within 6 hours of booking—and provide 24/7 management support for service inquiries or issues. Our goal is to save you time while ensuring satisfaction with our work, delivered by experienced and trustworthy staff.",
    aboutP3: "FreshMclean combines expertise, eco-friendly practices, and competitive pricing. We use the right products to protect your surfaces, extending the life of your furniture and appliances. Let us make your life easier—place your first order today!",
    advantages: "Advantages of using FreshMclean",
    idealCleaner: "Your ideal cleaner",
    idealCleanerText: "With our user-friendly platform, you can effortlessly enjoy the choice of picking diverse cleaning services to suit your talord needs and read through reviews of our professional cleaners. In just a few simple clicks, you can re-book the cleaner who best meets your needs and preferences.",
    customerService: "Reliable Customer Service",
    customerServiceText: "Our dedicated support team is available Monday through Saturday, from 8 AM to 5 PM, to assist you via email and WhatsApp. We're here to ensure your queries are addressed promptly and thoroughly.",
    liabilityInsured: "Liability Insured",
    liabilityInsuredText: "Your safety is our top priority. That's why each professional cleaner on our platform is covered by liability insurance of up to €5 million. You can find more details about this coverage in our comprehensive terms and conditions.",
    experience: "Experience World-class Cleaning With Us",
    experienceText: "Experience the difference in cleanliness and service quality with KleanUp. Contact us today to discuss your cleaning needs and let us make your, space shine!",
    
  },
  pricing: {
    title: 'Pricing Plans',
    description: 'We believe that the true measure of our success lies in the experiences and voices of our valued customers.',
    basicCleaning: 'Basic Cleaning',
    standardCleaning: 'Standard Cleaning',
    advancedCleaning: 'Advanced Cleaning',
    officeCleaning: 'Office Cleaning',
    perHour: '$/hour',
    descriptionBasic: 'Perfect for small homes and basic cleaning needs',
    descriptionAdvanced: 'A more advanced cleaning plan for larger homes',
    descriptionStandard: 'Ideal for regular maintenance and medium-sized homes',
    descriptionOffice: 'Complete cleaning solution for larger homes and offices',
    dusting: "Dusting",
    mopping: "Mopping",
    vacuuming: "Vacuuming",
    windowCleaning: "Window Cleaning",
    bathroomSanitization: "Bathroom Sanitization",
  },
  reviews: {
    title: 'Testimonials and Reviews',
    description: 'Real Stories, Real Clean Spaces – Hear What Our Customers Say!',
  },
  schedule: {
    title: 'Schedule Service',
    selectDate: 'Select Date',
    selectTime: 'Select Time',
    book: 'Book'
  },
  faq: {
    title: "Questions? Check here",
    subtitle: "If you do not find answers to your question here, do not hesitate to reach out to us.",
    questionSections: "Question Sections",
    general: "General",
    services: "Services",
    homeCleaning: "Home Cleaning",
    officeCleaning: "Office Cleaning",
    billing: "Billing",
  },
  cleaner: {
    title: "Welcome to FreshMclean",
    subtitle: "At FreshMclean, we believe in creating a workplace that values its team members as much as it values its clients. Joining our cleaning team means becoming a crucial part of a company committed to excellence and growth.",
    reasonTitle: "Here's why you should consider being a part of the FreshMclean family:",
    fulfilling: "Fulfilling Work",
    fulfilling1: "Contribute to creating clean and organized spaces that make a real difference in people's lives.",
    fulfilling2: "Experience the satisfaction of seeing the immediate impact of your work on our clients' environments.",
    competitive: "Competitive Compensation",
    competitive1: "Enjoy a competitive and fair compensation package that recognizes your hard work and dedication.",
    competitive2: "Benefit from performance-based incentives and bonuses as you excel in your role.",
    supportive: "Supportive Team Environment:",
    supportive1: "Join a team of dedicated and friendly professionals who foster a positive and collaborative work environment.",
    supportive2: "Receive continuous support and encouragement from your colleagues and supervisors.",
    training: "Training and Development:",
    training1: "Access comprehensive training programs to enhance your skills and knowledge in cleaning techniques and customer service.",
    training2: "Opportunities for professional growth and advancement within the company.",
    employee: "Employee Benefits:",
    employee1: "Enjoy a range of employee benefits, including health insurance, wellness programs, and other perks.",
    employee2: "Participate in team-building activities and events that strengthen the bond among team members.",
    fulfilling: "Fulfilling Work",
    fulfilling1: "Your hard work and commitment will be recognized and appreciated through regular acknowledgments and awards.",
    fulfilling2: "Be a part of a company culture that values and celebrates the contributions of its team members.",
    applyNow: "Apply now",
    howItWorks: "How it works",
    fill: "Fill in the application",
    fillText: "Start your journey with us by completing our simple and straightforward application process. Your first step toward joining FreshMclean!",
    interview: "Interview scheduling",
    interviewText: "Start your journey with us by completing our simple and straightforward application process. Your first step toward joining FreshMclean!",
    onboarding: "Onboarding",
    onboardingText: "Get ready to learn the ropes! We'll guide you through everything you need to know to succeed at FreshMclean.",
    welcome: "Welcome to FreshMclean",
    welcomeText: "Congratulations on joining the team! We're excited to have you on board and look forward to working together.",
    cleanerApplication: "Cleaner Application",
    commit: "Committed to Cleaning, Every Step of the Way"
  },
  dashboard: {
    home: "Home",
    cleaningRequest: "Cleaning Requests",
    savedAddress: "Saved Address",
    cleaningHistory: "Cleaning History",
    hello: "Hello",
    dashboardText: "Welcome to your dashboard. What do you plan to get done today? Book a cleaning request and we'll be with you in a jiffy!",
    placeOrder: "Place your cleaning order",
    referralBonus: "Referral Bonus",
    refer: "Refer a friend",
    referralBonusText: "Our Refer a Friend program is our way of expressing gratitude for your trust and loyalty",
    referText: "Promo starts at the second successful order",
    regularCleaning: "Regular Cleaning",
    regularSubtitle: "No long-term contracts – just quality cleaning, on your terms.",
    chooseAnOption: "Choose an option",
    pickDate: "Pick a suitable date and time for your cleaning",
    pickDateSubtitle: "Your cleaner comes fully equipped with all the necessary cleaning tools and solutions.",
    selectDate: "Select date",
    selectTime: "Select time",
    inputDetails: "Input your address and contact details",
    inputDetailsSubtitle: "Your cleaner comes fully equipped with all the necessary cleaning tools and solutions.",
    address: "Address",
    selectAddress: "Select address",
    streetName: "Street name",
    postCode: "Post code",
    building: "Building",
    city: "City",
    houseNumber: "House number",
    floorNumber: "Floor number",
    contactDetails: "Contact details",
    name: "Name",
    email: "Email address",
    contact: "Contact",
    additional: "Additional information",
    bookCleaning: "Book cleaning",
    addressInformation: "Address information",
    addAddress: "Add new address",
    actions: "Action",
    editAddress: "Edit Address",
    orderHistory: "Order History",
    orderId: "Order ID",
    desireDate: "Desire date",
    orderType: "Order type",
    status: "Status",
    noOrder: "There are no order histories",
    showing: "Showing",
    next: "Next",
    prev: "Prev"
  },
}