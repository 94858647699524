import Reliability from "../assests/images/values/reliability.png";
import Customers from "../assests/images/values/customer.png";
import Badge from "../assests/images/values/badge.png";
import { useTranslation } from "react-i18next";

export const valuesArr = [
  {
    imgSrc: Badge,
    valueKey: "about.idealCleaner",
    descKey: "about.idealCleanerText",
  },
  {
    imgSrc: Reliability,
    valueKey: "about.customerService",
    descKey: "about.customerServiceText",
  },
  {
    imgSrc: Customers,
    valueKey: "about.liabilityInsured",
    descKey: "about.liabilityInsuredText",
  },
];
