import * as yup from "yup";
import { useFormik } from "formik";
// import ShowModal from "../muiModal/modal";
// import { ModalRequestDetail } from "../modalView/CleanerModal";
import axios from "axios";
import Card from "../../assests/images/debit-card.png";
import { useState, useEffect } from "react";

import { useTranslation } from 'react-i18next'

const customerContact = yup.object({
  streetName: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  postCode: yup.string().required("Post code is required. "),
  houseNumber: yup.string().required("House Number is required."),
  building: yup.string().required("Building is required."),
  entranceNumber: yup.string().required("Floor number is required."),
  phoneNumber: yup.string().required("Phone number is required"),
  name: yup.string().required("Name is required."),
  email: yup
    .string()
    .required("Email is required. ")
    .email("Please enter a valid email address"),
  additionalInformation: yup.string(),
});

export default function Contact({ toggleModal, contacts, paymentMethod }) {
  const customerId = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = sessionStorage.getItem("token");

  const { t } = useTranslation();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedAddressId, setSelectedAddressId] = useState("");
  // const handlePaymentMethodChange = (method) => {
  //   setSelectedPaymentMethod(method);
  // };

  const fetchAddresses = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/getAllAddressInformation/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setAddresses(data.AddressInformation);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);




  const onSubmit = (values) => {
    contacts(values);
    // paymentMethod(selectedPaymentMethod);
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    // handleReset,
    touched,
    errors,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {
      streetName: "",
      city: "",
      postCode: "",
      houseNumber: "",
      building: "",
      entranceNumber: "",
      phoneNumber: "",
      name: "",
      email: "",
      additionalInformation: "",
    },
    validationSchema: customerContact,
    onSubmit,
  });

  const handleAddressChange = (e) => {
    const selectedId = e.target.value;
    setSelectedAddressId(selectedId);

    const selected = addresses.find((address) => address._id === selectedId);
    if (selected) {
      // Update form fields with the selected address details
      setFieldValue("streetName", selected.streetName || "");
      setFieldValue("city", selected.city || "");
      setFieldValue("postCode", selected.postCode || "");
      setFieldValue("houseNumber", selected.houseNumber || "");
      setFieldValue("building", selected.building || "");
      setFieldValue("entranceNumber", selected.entranceNumber || "");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center my-5 px-4">
        <div className="text-center mb-10 flex flex-col items-center">
          <p className="text-2xl font-medium max-w-[320px] mb-5">
            {t('dashboard.inputDetails')}
          </p>
          <p className="text-gray-500 max-w-[450px]">
            {t('dashboard.inputDetailsSubtitle')}
          </p>
        </div>
        <div className="flex w-full justify-center">
          <form
            className="border p-8 rounded-lg w-full md:w-[500px] lg:w-[630px] mx-4"
            style={{ borderColor: "#3DA5EC" }}
          >
            <h3 className="text-lg font-semibold mb-4">{t('dashboard.address')}</h3>

            <div className="form-group">
              <label>{t('dashboard.selectAddress')}</label>
              <select
                className="block border-2 mt-1 border-gray-400 bg-gray-50 px-2 py-2.5 w-full max-lg:w-full outline-none rounded-lg"
                onChange={handleAddressChange}
                value={selectedAddress._id || ""}
              >
                <option value="">Select option</option>
                {addresses.map((address) => (
                  <option key={address._id} value={address._id}>
                    {address.streetName}, {address.city}
                  </option>
                ))}
              </select>
            </div>

            <hr className="my-6" />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <div>
                <label className="block mb-1 ">{t('dashboard.streetName')}</label>
                <input
                  // placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2 w-full max-lg:w-full outline-none rounded-lg"
                  onBlur={handleBlur("streetName")}
                  onChange={handleChange("streetName")}
                  value={values.streetName}
                />
                {errors.streetName && touched.streetName && (
                  <p className="text-[#ff0000]">{errors.streetName}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 ">{t('dashboard.postCode')}</label>
                <input
                  // placeholder="PO-023"
                  className="block border-2 border-gray-400  max-lg:w-full p-2 w-full outline-none rounded-lg"
                  onBlur={handleBlur("postCode")}
                  onChange={handleChange("postCode")}
                  value={values.postCode}
                />
                {errors.postCode && touched.postCode && (
                  <p className="text-[#ff0000]">{errors.postCode}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 ">{t('dashboard.building')}</label>
                <input
                  // placeholder="RH Books Building, No 1234"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-full outline-none rounded-lg"
                  onBlur={handleBlur("building")}
                  onChange={handleChange("building")}
                  value={values.building}
                />
                {errors.building && touched.building && (
                  <p className="text-[#ff0000]">{errors.building}</p>
                )}
              </div>

              <div>
                <label className="block mb-1 ">{t('dashboard.city')}</label>
                <input
                  // placeholder="123 Maple Street"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-full outline-none rounded-lg"
                  onBlur={handleBlur("city")}
                  onChange={handleChange("city")}
                  value={values.city}
                />
                {errors.city && touched.city && (
                  <p className="text-[#ff0000]">{errors.city}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 ">{t('dashboard.houseNumber')}</label>
                <input
                  // placeholder="123 Maple Street"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-full outline-none rounded-lg"
                  onBlur={handleBlur("houseNumber")}
                  onChange={handleChange("houseNumber")}
                  value={values.houseNumber}
                />
                {errors.houseNumber && touched.houseNumber && (
                  <p className="text-[#ff0000]">{errors.houseNumber}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 ">{t('dashboard.floorNumber')}</label>
                <input
                  // placeholder="123 Maple Street"
                  className="block border-2  max-lg:w-full border-gray-400 p-2 w-full outline-none rounded-lg"
                  onBlur={handleBlur("entranceNumber")}
                  onChange={handleChange("entranceNumber")}
                  value={values.entranceNumber}
                  placeholder="e.g, First floor"
                />
                {errors.entranceNumber && touched.entranceNumber && (
                  <p className="text-[#ff0000]">{errors.entranceNumber}</p>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="flex w-full justify-center">
          <form
            className="mt-4 border p-8 rounded-lg w-full md:w-[500px] lg:w-[630px] mx-4"
            style={{ borderColor: "#3DA5EC" }}
          >
            <h3 className="text-lg font-semibold mb-4">{t('dashboard.contactDetails')}</h3>
            <div className="">
              <div className="mb-5">
                <label className="block mb-1 ">{t('dashboard.name')}</label>
                <input
                  // placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2 w-full outline-none rounded-lg"
                  onBlur={handleBlur("name")}
                  onChange={handleChange("name")}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <p className="text-[#ff0000]">{errors.name}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1">{t('dashboard.contact')}</label>
                <input
                  // placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2  outline-none rounded-lg w-full"
                  onBlur={handleBlur("phoneNumber")}
                  onChange={handleChange("phoneNumber")}
                  value={values.phoneNumber}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <p className="text-[#ff0000]">{errors.phoneNumber}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">{t('dashboard.email')}</label>
                <input
                  // placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2  outline-none rounded-lg w-full"
                  onBlur={handleBlur("email")}
                  onChange={handleChange("email")}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <p className="text-[#ff0000]">{errors.email}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block mb-1 ">{t('dashboard.additional')}</label>
                <textarea
                  // placeholder="123 Maple Street"
                  className="block border-2 border-gray-400 p-2  outline-none rounded-lg w-full"
                  rows={10}
                  cols={8}
                  onBlur={handleBlur("additionalInformation")}
                  onChange={handleChange("additionalInformation")}
                  value={values.additionalInformation}
                ></textarea>
                {errors.additionalInformation &&
                  touched.additionalInformation && (
                    <p className="text-[#ff0000]">
                      {errors.additionalInformation}
                    </p>
                  )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center pb-20 mx-4 bg-sky-50">
        {/* <div className="text-center mb-20">
          <p className="text-2xl font-helvetica-neue font-medium mb-3">
            Select payment method
          </p>
          <p className="text-gray-500 max-w-[470px]">
            Your cleaner comes fully equipped with all the necessary cleaning
            tools and solutions.
          </p>
        </div> */}
        <div className="flex flex-col w-full justify-center items-center">
          {/* <div className="bg-white shadow-sm pt-10 pl-10 rounded-xl flex flex-col w-[700px] max-md:w-[600px] overflow-hidden max-sm:w-full mb-5">
            <div className="h-[250px]">
              <p className="text-xl font-medium mb-3">
                Select your payment method
              </p>
              <p className="max-w-[400px] text-gray-500 mb-5 mr-2">
                Your cleaner comes fully equipped with all the necessary
                cleaning tools and solutions.
              </p>

              <div className="w-full pr-10">
                <label
                  htmlFor="payment-method"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Select Payment Method:
                </label>
                <select
                  id="payment-method"
                  value={selectedPaymentMethod}
                  onChange={handleSelectionChange}
                  className="block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="" disabled>
                    Choose a payment method
                  </option>
                  {paymentMethods.map((method) => (
                    <option key={method._id} value={method._id}>
                      {method.paymentType} - ****{method.cardNumber.slice(-4)}
                    </option>
                  ))}
                </select>
                {selectedPaymentMethod && (
                  <p className="mt-3 text-sm text-gray-500">
                    You selected:{" "}
                    <span className="font-semibold text-gray-800">
                      {
                        paymentMethods.find(
                          (method) => method._id === selectedPaymentMethod
                        )?.paymentType
                      }
                    </span>
                  </p>
                )}
              </div>
            </div>
            <img src={Card} alt="Card" className="w-[300px] ml-auto self-end" />
          </div> */}

          <button
            className={`py-3 rounded-lg text-white font-medium mt-20 w-[700px] max-md:w-[600px] max-md:w-full ${
              values.streetName &&
              values.city &&
              values.postCode &&
              values.houseNumber &&
              values.building &&
              values.entranceNumber &&
              values.phoneNumber &&
              values.name &&
              values.email
                ? "bg-primary-sky"
                : "bg-gray-400"
            }`}
            onClick={() => {
              if (
                values.streetName &&
                values.city &&
                values.postCode &&
                values.houseNumber &&
                values.building &&
                values.entranceNumber &&
                values.phoneNumber &&
                values.name &&
                values.email
              ) {
                toggleModal();
                handleSubmit();
              } else {
                alert("Please complete all fields before booking.");
              }
            }}
            disabled={
              !values.streetName ||
              !values.city ||
              !values.postCode ||
              !values.houseNumber ||
              !values.building ||
              !values.entranceNumber ||
              !values.phoneNumber ||
              !values.name ||
              !values.email
            }
          >
            {t('dashboard.bookCleaning')}
          </button>
        </div>
      </div>
    </>
  );
}
