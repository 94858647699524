const Countries = [
    { name: "Algeria", code: "+213", abbreviation: "DZA" },
    { name: "Angola", code: "+244", abbreviation: "AGO" },
    { name: "Argentina", code: "+54", abbreviation: "ARG" },
    { name: "Austria", code: "+43", abbreviation: "AUT" },
    { name: "Belgium", code: "+32", abbreviation: "BEL" },
    { name: "Benin", code: "+229", abbreviation: "BEN" },
    { name: "Botswana", code: "+267", abbreviation: "BWA" },
    { name: "Brazil", code: "+55", abbreviation: "BRA" },
    { name: "Burkina Faso", code: "+226", abbreviation: "BFA" },
    { name: "Burundi", code: "+257", abbreviation: "BDI" },
    { name: "Cameroon", code: "+237", abbreviation: "CMR" },
    { name: "Canada", code: "+1", abbreviation: "CAN" },
    { name: "Cape Verde", code: "+238", abbreviation: "CPV" },
    { name: "Chad", code: "+235", abbreviation: "TCD" },
    { name: "Chile", code: "+56", abbreviation: "CHL" },
    { name: "Comoros", code: "+269", abbreviation: "COM" },
    { name: "Congo (Brazzaville)", code: "+242", abbreviation: "COG" },
    { name: "Congo (Kinshasa)", code: "+243", abbreviation: "COD" },
    { name: "Côte d'Ivoire", code: "+225", abbreviation: "CIV" },
    { name: "Croatia", code: "+385", abbreviation: "HRV" },
    { name: "Cyprus", code: "+357", abbreviation: "CYP" },
    { name: "Czech Republic", code: "+420", abbreviation: "CZE" },
    { name: "Denmark", code: "+45", abbreviation: "DNK" },
    { name: "Djibouti", code: "+253", abbreviation: "DJI" },
    { name: "Egypt", code: "+20", abbreviation: "EGY" },
    { name: "Equatorial Guinea", code: "+240", abbreviation: "GNQ" },
    { name: "Eswatini", code: "+268", abbreviation: "SWZ" },
    { name: "Ethiopia", code: "+251", abbreviation: "ETH" },
    { name: "Finland", code: "+358", abbreviation: "FIN" },
    { name: "France", code: "+33", abbreviation: "FRA" },
    { name: "Gabon", code: "+241", abbreviation: "GAB" },
    { name: "Gambia", code: "+220", abbreviation: "GMB" },
    { name: "Germany", code: "+49", abbreviation: "DEU" },
    { name: "Ghana", code: "+233", abbreviation: "GHA" },
    { name: "Greece", code: "+30", abbreviation: "GRC" },
    { name: "Guinea", code: "+224", abbreviation: "GIN" },
    { name: "Guinea-Bissau", code: "+245", abbreviation: "GNB" },
    { name: "Hungary", code: "+36", abbreviation: "HUN" },
    { name: "Iceland", code: "+354", abbreviation: "ISL" },
    { name: "Ireland", code: "+353", abbreviation: "IRL" },
    { name: "Italy", code: "+39", abbreviation: "ITA" },
    { name: "Kenya", code: "+254", abbreviation: "KEN" },
    { name: "Lesotho", code: "+266", abbreviation: "LSO" },
    { name: "Liberia", code: "+231", abbreviation: "LBR" },
    { name: "Libya", code: "+218", abbreviation: "LBY" },
    { name: "Lithuania", code: "+370", abbreviation: "LTU" },
    { name: "Luxembourg", code: "+352", abbreviation: "LUX" },
    { name: "Madagascar", code: "+261", abbreviation: "MDG" },
    { name: "Malawi", code: "+265", abbreviation: "MWI" },
    { name: "Mali", code: "+223", abbreviation: "MLI" },
    { name: "Malta", code: "+356", abbreviation: "MLT" },
    { name: "Mauritania", code: "+222", abbreviation: "MRT" },
    { name: "Mauritius", code: "+230", abbreviation: "MUS" },
    { name: "Morocco", code: "+212", abbreviation: "MAR" },
    { name: "Mozambique", code: "+258", abbreviation: "MOZ" },
    { name: "Namibia", code: "+264", abbreviation: "NAM" },
    { name: "Netherlands", code: "+31", abbreviation: "NLD" },
    { name: "Niger", code: "+227", abbreviation: "NER" },
    { name: "Nigeria", code: "+234", abbreviation: "NGN" },
    { name: "Norway", code: "+47", abbreviation: "NOR" },
    { name: "Poland", code: "+48", abbreviation: "POL" },
    { name: "Portugal", code: "+351", abbreviation: "PRT" },
    { name: "Rwanda", code: "+250", abbreviation: "RWA" },
    { name: "Senegal", code: "+221", abbreviation: "SEN" },
    { name: "Seychelles", code: "+248", abbreviation: "SYC" },
    { name: "Sierra Leone", code: "+232", abbreviation: "SLE" },
    { name: "Slovakia", code: "+421", abbreviation: "SVK" },
    { name: "Slovenia", code: "+386", abbreviation: "SVN" },
    { name: "Somalia", code: "+252", abbreviation: "SOM" },
    { name: "South Africa", code: "+27", abbreviation: "ZAF" },
    { name: "South Sudan", code: "+211", abbreviation: "SSD" },
    { name: "Spain", code: "+34", abbreviation: "ESP" },
    { name: "Sudan", code: "+249", abbreviation: "SDN" },
    { name: "Sweden", code: "+46", abbreviation: "SWE" },
    { name: "Switzerland", code: "+41", abbreviation: "CHE" },
    { name: "Tanzania", code: "+255", abbreviation: "TZA" },
    { name: "Togo", code: "+228", abbreviation: "TGO" },
    { name: "Tunisia", code: "+216", abbreviation: "TUN" },
    { name: "Uganda", code: "+256", abbreviation: "UGA" },
    { name: "United Kingdom", code: "+44", abbreviation: "UK" },
    { name: "United States", code: "+1", abbreviation: "USA" },
    { name: "Zambia", code: "+260", abbreviation: "ZMB" },
    { name: "Zimbabwe", code: "+263", abbreviation: "ZWE" },
];


export default Countries;