import Navigation from "../components/nav";
import PriceTag from "../assests/images/price-tag.png";
import Dollar from "../assests/images/dollar.png";
import Footer from "../components/home-ui-components/footer";
import { useTranslation } from "react-i18next";
// import PricePage from "../components/pricecards";
import { pricingArr } from "../constants/pricings";
import { Link } from "react-router-dom";


export default function Price() {
  const { t } = useTranslation();
  
  return (
    <div className="overflow-hidden">
      <div className="bg-white">
        <Navigation />
      </div>
      <div className="bg-black py-24 md:py-6 flex justify-center md:justify-between items-center">
        <img
          src={Dollar}
          alt=""
          className="max-lg:w-[170px] max-md:w-[130px] hidden md:block"
        />
        <div className="text-white text-center">
          <p className="font-medium text-3xl mb-5 max-md:text-xl">
            {t('pricing.title')}
          </p>
          <p className="inline-block w-[95%] md:w-[60%] lg:w-[55%] max-md:text-sm">
            {t('pricing.description')}
          </p>
        </div>
        <img
          src={PriceTag}
          alt=""
          className="max-lg:w-[170px] max-md:w-[130px] hidden md:block"
        />
      </div>
      {/* <div className="bg-white flex justify-center py-20" style={{ backgroundColor: "#e2e2e2" }}>
        <PriceLog />
      </div> */}

      <div class="py-20 px-4 md:px-24 lg:px-20 xl:px-40">
        <div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {pricingArr.map((pricing, index) => (
              <div
                key={index}
                className={`px-5 pt-8 md:pt-6 pb-3 rounded-lg ${pricing.color} flex flex-col h-full`}
              >
                <div>
                  <div>
                    <h4 className="text-xl font-semibold">{t(pricing.typeKey)}</h4>
                    <p className="font-medium mt-1 text-sm">{t(pricing.remarkKey)}</p>
                  </div>

                  <div className="flex items-center space-x-1 mb-3 mt-6">
                    <p className="text-xl font-medium">&euro;</p>
                    <h3 className="text-4xl font-semibold">{pricing.price}</h3>

                    <div className="self-end">
                      <p className="text-md font-semibold mb-1">/hour</p>
                    </div>
                  </div>
                </div>

                <Link to={pricing.url} className="mt-auto">
                  <button className="bg-slate-950 w-full rounded-lg text-white px-2 py-2.5 text-sm font-medium">
                    Pay now
                  </button>
                </Link>
              </div>

            ))}
          </div>

          <div className="mt-10 overflow-x-auto rounded-lg">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                    Plan
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                    {t('pricing.basicCleaning')}
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                    {t('pricing.deepCleaning')}
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                    {t('pricing.advancedCleaning')}
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                    {t('pricing.officeCleaning')}
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {/* Example Features Rows */}
                <tr className="even:bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-4 border-b border-gray-200">{t('pricing.dusting')}</td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    —
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                </tr>
                <tr className="even:bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-4 border-b border-gray-200">{t('pricing.mopping')}</td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    —
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                </tr>
                <tr className="even:bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-4 border-b border-gray-200">{t('pricing.vacuuming')}</td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    —
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                </tr>
                <tr className="even:bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-4 border-b border-gray-200">
                    {t('pricing.windowCleaning')}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    —
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    —
                  </td>
                </tr>
                <tr className="even:bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-4 border-b border-gray-200">
                    {t('pricing.bathroomSanitization')}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    —
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-center">
                    ✓
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <section>
        {/* <div className="bg-white py-20 sm:px-8 md:px-24 lg:px-20 xl:px-40">
          <div className="flex justify-between pb-10">
            <p className="text-2xl font-medium">FAQS</p>
            <p className="text-gray-500">See all</p>
          </div>
          <FAQs />
        </div> */}
        {/* <div className="mt-10 bg-white pt-10">
          <Partners />
        </div> */}
        
      </section>

      <div className="mt-20">
        <Footer />
      </div>
    </div>
  );
}
