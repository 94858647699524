export default {
  navigation: {
    home: 'Startseite',
    about: 'Über uns',
    pricing: 'Preise',
    reviews: 'Bewertungen',
    schedule: 'Terminplan',
    login: 'Anmelden',
    register: 'Registrieren',
    dashboard: 'Dashboard',
    faqs: 'FAQs',
    becomeCleaner: 'Reinigungskraft werden'
  },
  utility: {
  createAccount: "Konto erstellen",
  openYourDashboard: "Öffnen Sie Ihr Dashboard",
  benefits: "Vorteile",
  bookACleaning: "Reinigung buchen",
  subscribe: "Abonnieren",
  getStarted: "Loslegen",
},
home: {
  hero: {
    title: 'Reinigungsdienste für Ihr Zuhause',
    subtitle: 'Professionelle Reinigungsdienste für Ihr Zuhause',
    cta: 'Jetzt starten',
    proceedToDashboard: "Zum Dashboard fortfahren",
  },
  services: {
    renderedServices: "Erbrachte Dienstleistungen",
    weAre: "Wir sind ",
    experienceText: "Erleben Sie Reinigungsdienste, die auf Ihre Bedürfnisse und Ihren Zeitplan zugeschnitten sind. Wir sorgen für den Glanz, Sie genießen den Komfort.",
    howItWorks: "Wie es funktioniert",
    innerWorking: "Die Funktionsweise unseres Dienstes!",
    cleanText: "Wir verstehen, dass eine saubere und gut gepflegte Umgebung nicht nur eine Vorliebe, sondern eine Notwendigkeit ist",
    bookYourCleaning: "Buchen Sie Ihre Reinigung",
    chooseBooking: "Wählen Sie Ihren Reinigungstyp, die Häufigkeit sowie das bevorzugte Datum und die Uhrzeit.",
    pricing: "Preise",
    pricingText: "Unsere Preisstruktur ist transparent und wettbewerbsfähig. Wählen Sie aus einer Vielzahl von Paketen oder passen Sie sie an Ihre Bedürfnisse an.",
    testimonials: "Kundenbewertungen",
    testimonialsText: "Wir glauben, dass der wahre Maßstab unseres Erfolgs in den Erfahrungen und Stimmen unserer geschätzten Kunden liegt.",
    getInTouch: "Kontakt aufnehmen",
    getInTouchText: "Wir helfen Ihnen gerne bei Ihren Reinigungswünschen.",
    contactUsText: "Ob Sie einziehen oder ausziehen – unser erstklassiger Reinigungsservice bereitet Ihr Zuhause mit einer gründlichen Reinigung vor, die den Mietstandards entspricht.",
    contactWhatsapp: "Kontaktieren Sie uns über WhatsApp",
    partners: "Partner",
    footerText: "Unser Team erfahrener und zuverlässiger Reinigungskräfte ist bestrebt, erstklassige Reinigungsdienste für Ihr Zuhause anzubieten."
  },
},
  about: {
    title: "Über uns",
    subtitle: "FreshMclean Reinigungsdienste für Ihr Zuhause auf Knopfdruck",
    mission: "Unsere Mission",
    missionP1: "Unsere Mission ist einfach: erstklassige Reinigungslösungen bereitzustellen, die die Erwartungen übertreffen und gleichzeitig die Kundenzufriedenheit sicherstellen.",
    missionP2: "Wir streben danach, makellose Umgebungen zu schaffen, die zu Ihrem Komfort und Wohlbefinden beitragen.",
    heroText: "Wir sind bestrebt, außergewöhnliche Reinigungsdienste zu liefern, die auf Ihre Bedürfnisse zugeschnitten sind. Mit jahrelanger Erfahrung und einem Team aus qualifizierten Fachleuten sind wir stolz darauf, saubere und gesunde Räume für unsere Kunden zu schaffen.",
    aboutP1: "Bei FreshMclean legen wir Wert auf Professionalität, Zuverlässigkeit und Komfort. Wir erfüllen die Erwartungen unserer Kunden, bieten flexible Dienstleistungen und priorisieren eine langfristige Zusammenarbeit. Unsere Dienstleistungen sind jederzeit verfügbar, auch frühmorgens oder spätabends, mit transparenten Reinigungszeiten, die Ihnen helfen, effizient zu planen.",
    aboutP2: "Wir handeln schnell – wir kommen innerhalb von 6 Stunden nach der Buchung – und bieten einen 24/7-Management-Support für Serviceanfragen oder Probleme. Unser Ziel ist es, Ihnen Zeit zu sparen und gleichzeitig sicherzustellen, dass Sie mit unserer Arbeit zufrieden sind, die von erfahrenem und vertrauenswürdigem Personal ausgeführt wird.",
    aboutP3: "FreshMclean kombiniert Fachwissen, umweltfreundliche Praktiken und wettbewerbsfähige Preise. Wir verwenden die richtigen Produkte, um Ihre Oberflächen zu schützen und die Lebensdauer Ihrer Möbel und Geräte zu verlängern. Lassen Sie uns Ihr Leben erleichtern – geben Sie noch heute Ihre erste Bestellung auf!",
    advantages: "Vorteile der Nutzung von FreshMclean",
    idealCleaner: "Ihr idealer Reiniger",
    idealCleanerText: "Mit unserer benutzerfreundlichen Plattform können Sie mühelos aus einer Vielzahl von Reinigungsdiensten wählen, die auf Ihre individuellen Bedürfnisse zugeschnitten sind, und Bewertungen unserer professionellen Reiniger durchlesen. Mit nur wenigen Klicks können Sie den Reiniger erneut buchen, der am besten zu Ihren Anforderungen und Vorlieben passt.",
    customerService: "Zuverlässiger Kundenservice",
    customerServiceText: "Unser engagiertes Support-Team steht Ihnen von Montag bis Samstag, von 8:00 bis 17:00 Uhr, per E-Mail und WhatsApp zur Verfügung. Wir sind hier, um sicherzustellen, dass Ihre Anfragen schnell und umfassend bearbeitet werden.",
    liabilityInsured: "Haftpflichtversicherung",
    liabilityInsuredText: "Ihre Sicherheit hat für uns oberste Priorität. Deshalb ist jeder professionelle Reiniger auf unserer Plattform durch eine Haftpflichtversicherung von bis zu 5 Millionen € abgesichert. Weitere Details zu diesem Schutz finden Sie in unseren umfassenden Allgemeinen Geschäftsbedingungen.",
    experience: "Erleben Sie erstklassige Reinigung mit uns",
    experienceText: "Erleben Sie den Unterschied in Sauberkeit und Servicequalität mit KleanUp. Kontaktieren Sie uns noch heute, um Ihre Reinigungsbedürfnisse zu besprechen, und lassen Sie uns Ihren Raum zum Strahlen bringen!",
  },
  "pricing": {
    "title": "Preispläne",
    "description": "Wir glauben, dass der wahre Maßstab unseres Erfolgs in den Erfahrungen und Stimmen unserer geschätzten Kunden liegt.",
    "basicCleaning": "Grundreinigung",
    "standardCleaning": "Standardreinigung",
    "advancedCleaning": "Erweiterte Reinigung",
    "officeCleaning": "Büroreinigung",
    "perHour": "$/Stunde",
    "descriptionBasic": "Perfekt für kleine Wohnungen und grundlegende Reinigungsbedürfnisse",
    "descriptionAdvanced": "Ein fortgeschrittener Reinigungsplan für größere Wohnungen",
    "descriptionStandard": "Ideal für regelmäßige Wartung und mittelgroße Wohnungen",
    "descriptionOffice": "Komplette Reinigungslösung für größere Wohnungen und Büros",
    "dusting": "Abstauben",
    "mopping": "Wischen",
    "vacuuming": "Staubsaugen",
    "windowCleaning": "Fensterreinigung",
    "bathroomSanitization": "Badezimmerdesinfektion"
  },
  reviews: {
    title: 'Erfahrungsberichte und Bewertungen',
    description: 'Echte Geschichten, echte saubere Räume – hören Sie, was unsere Kunden sagen!',
  },
  schedule: {
    title: 'Termin planen',
    selectDate: 'Datum auswählen',
    selectTime: 'Uhrzeit auswählen',
    book: 'Buchen'
  },
  faq: {
    title: "Fragen? Schauen Sie hier",
    subtitle: "Falls Sie hier keine Antwort auf Ihre Frage finden, zögern Sie nicht, uns zu kontaktieren.",
    questionSections: "Fragenbereiche",
    general: "Allgemein",
    services: "Dienstleistungen",
    homeCleaning: "Haushaltsreinigung",
    officeCleaning: "Büroreinigung",
    billing: "Abrechnung",
  },
  cleaner: {
    title: "Willkommen bei FreshMclean",
    subtitle: "Bei FreshMclean legen wir großen Wert darauf, ein Arbeitsumfeld zu schaffen, das seine Teammitglieder genauso schätzt wie seine Kunden. Teil unseres Reinigungsteams zu werden, bedeutet, ein wesentlicher Bestandteil eines Unternehmens zu sein, das sich der Exzellenz und dem Wachstum verschrieben hat.",
    reasonTitle: "Darum sollten Sie Teil der FreshMclean-Familie werden:",
    fulfilling: "Erfüllende Arbeit",
    fulfilling1: "Tragen Sie dazu bei, saubere und organisierte Räume zu schaffen, die einen echten Unterschied im Leben der Menschen machen.",
    fulfilling2: "Erleben Sie die Zufriedenheit, den direkten Einfluss Ihrer Arbeit auf die Umgebung unserer Kunden zu sehen.",
    competitive: "Wettbewerbsfähige Vergütung",
    competitive1: "Profitieren Sie von einem wettbewerbsfähigen und fairen Vergütungspaket, das Ihre harte Arbeit und Hingabe anerkennt.",
    competitive2: "Erhalten Sie leistungsbezogene Anreize und Boni, wenn Sie in Ihrer Rolle glänzen.",
    supportive: "Unterstützendes Teamumfeld",
    supportive1: "Werden Sie Teil eines engagierten und freundlichen Teams, das ein positives und kooperatives Arbeitsumfeld fördert.",
    supportive2: "Erhalten Sie kontinuierliche Unterstützung und Ermutigung von Ihren Kollegen und Vorgesetzten.",
    training: "Schulung und Entwicklung",
    training1: "Nutzen Sie umfassende Schulungsprogramme, um Ihre Fähigkeiten und Kenntnisse in Reinigungstechniken und Kundenservice zu verbessern.",
    training2: "Möglichkeiten für berufliches Wachstum und Weiterentwicklung innerhalb des Unternehmens.",
    employee: "Mitarbeitervorteile",
    employee1: "Genießen Sie eine Vielzahl von Mitarbeitervorteilen, einschließlich Krankenversicherung, Wellness-Programme und weitere Extras.",
    employee2: "Nehmen Sie an Teambuilding-Aktivitäten und Veranstaltungen teil, die die Bindung unter den Teammitgliedern stärken.",
    applyNow: "Jetzt bewerben",
    howItWorks: "Wie es funktioniert",
    fill: "Füllen Sie das Bewerbungsformular aus",
    fillText: "Beginnen Sie Ihre Reise mit uns, indem Sie unseren einfachen und unkomplizierten Bewerbungsprozess abschließen. Ihr erster Schritt, um Teil von FreshMclean zu werden!",
    interview: "Interviewplanung",
    interviewText: "Planen Sie ein Gespräch, um mehr über uns zu erfahren und uns die Möglichkeit zu geben, mehr über Sie zu erfahren.",
    onboarding: "Einarbeitung",
    onboardingText: "Machen Sie sich bereit, alles zu lernen! Wir führen Sie durch alles, was Sie wissen müssen, um bei FreshMclean erfolgreich zu sein.",
    welcome: "Willkommen bei FreshMclean",
    welcomeText: "Herzlichen Glückwunsch zum Beitritt in unser Team! Wir freuen uns, Sie an Bord zu haben und auf die Zusammenarbeit mit Ihnen.",
    cleanerApplication: "Reinigungsbewerbung",
    commit: "Engagiert für Sauberkeit, Schritt für Schritt",
  },
  dashboard: {
    home: "Startseite",
    cleaningRequest: "Reinigungsanfragen",
    savedAddress: "Gespeicherte Adresse",
    cleaningHistory: "Reinigungshistorie",
    hello: "Hallo",
    dashboardText: "Willkommen in Ihrem Dashboard. Was möchten Sie heute erledigen? Buchen Sie eine Reinigungsanfrage und wir sind im Handumdrehen bei Ihnen!",
    placeOrder: "Reinigungsauftrag erstellen",
    referralBonus: "Empfehlungsbonus",
    refer: "Einen Freund werben",
    referralBonusText: "Unser Empfehlungsprogramm ist unsere Art, Ihnen für Ihr Vertrauen und Ihre Loyalität zu danken.",
    referText: "Die Aktion beginnt mit der zweiten erfolgreichen Bestellung.",
    regularCleaning: "Regelmäßige Reinigung",
    regularSubtitle: "Keine langfristigen Verträge – nur hochwertige Reinigung, nach Ihren Wünschen.",
    chooseAnOption: "Wählen Sie eine Option",
    pickDate: "Wählen Sie ein geeignetes Datum und eine Uhrzeit für Ihre Reinigung",
    pickDateSubtitle: "Ihr Reiniger kommt vollständig ausgestattet mit allen notwendigen Reinigungsutensilien und -lösungen.",
    selectDate: "Datum auswählen",
    selectTime: "Uhrzeit auswählen",
    inputDetails: "Geben Sie Ihre Adresse und Kontaktdaten ein",
    inputDetailsSubtitle: "Ihr Reiniger kommt vollständig ausgestattet mit allen notwendigen Reinigungsutensilien und -lösungen.",
    address: "Adresse",
    selectAddress: "Adresse auswählen",
    streetName: "Straßenname",
    postCode: "Postleitzahl",
    building: "Gebäude",
    city: "Stadt",
    houseNumber: "Hausnummer",
    floorNumber: "Stockwerksnummer",
    contactDetails: "Kontaktdaten",
    name: "Name",
    email: "E-Mail-Adresse",
    contact: "Kontakt",
    additional: "Zusätzliche Informationen",
    bookCleaning: "Reinigung buchen",
    addressInformation: "Adressinformationen",
    addAddress: "Neue Adresse hinzufügen",
    actions: "Aktion",
    editAddress: "Adresse bearbeiten",
    orderHistory: "Bestellhistorie",
    orderId: "Bestell-ID",
    desireDate: "Wunschtermin",
    orderType: "Bestelltyp",
    status: "Status",
    noOrder: "Es gibt keine Bestellhistorie",
    showing: "Anzeigen",
    next: "Weiter",
    prev: "Zurück",
  },
}