// import RenderPricings from "../renderPrices";
import ScheduleComp from "./scheduleComp";
import { useTranslation } from "react-i18next";

export default function Pricings() {
  const { t } = useTranslation();
  return (
    <div className="bg-primary-benefit flex justify-center py-20 w-full ">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="text-center flex items-center justify-center flex-col max-xs:px-2">
          <p className="text-4xl font-semibold mb-4">
            {t('home.services.pricing')}
          </p>
          <p
            className="text-blue-500 inline-block w-[97%] md:w-[65%] lg:w-[50%] text-sm md:text-base"
            style={{ color: "#577C95" }}
          >
            {t('home.services.pricingText')}
          </p>
        </div>
        <div className="flex justify-center items-center">
          <ScheduleComp />
        </div>
      </div>
    </div>
  );
}
