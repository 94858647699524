import Navigation from "../components/nav";
import QuestionBg from "../assests/images/question-bg.png";
import FAQs from "../components/home-ui-components/faqs";
import Footer from "../components/home-ui-components/footer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";


export default function Question() {
  const options = [
    "General",
    "Services",
    "Home cleaning",
    "Office cleaning",
    "Billing",
  ];
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const { t } = useTranslation();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleTabClick = (index) => {
    setSelectedTab(index);
  };
 

 

  return (
    <div className="overflow-hidden">
      <div>
        <Navigation />
      </div>
      <div
        className="h-[350px]"
        style={{
          backgroundImage: ` url(${QuestionBg})`,
        }}
      >
        <div className="question-container h-[350px] flex justify-center items-center font-helvetica-neue">
          <div className="text-white text-center">
            <h1 className="text-3xl mb-2">{t('faq.title')}</h1>
            <p className="max-w-[430px] text-md mt-2">
              {t('faq.subtitle')}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-x-20  md:px-4 lg:flex-row xs:flex-col lg:px-4">
        <div className="flex flex-col mt-10 xs:px-4 md:px-0 ">
          <p className="font-semibold text-2xl mb-10">Question sections</p>
          <div className="text-gray-400 text-lg flex flex-col gap-y-5 max-lg:hidden">
            <p
              className={`cursor-pointer ${
                selectedTab === 0 ? "text-black" : ""
              }`}
              onClick={() => handleTabClick(0)}
            >
              {t('faq.general')}
            </p>
            <p
              className={`cursor-pointer ${
                selectedTab === 1 ? "text-black" : ""
              }`}
              onClick={() => handleTabClick(1)}
            >
              {t('faq.services')}
            </p>
            <p
              className={`cursor-pointer ${
                selectedTab === 2 ? "text-black" : ""
              }`}
              onClick={() => handleTabClick(2)}
            >
              {t('faq.homeCleaning')}
            </p>
            <p
              className={`cursor-pointer ${
                selectedTab === 3 ? "text-black" : ""
              }`}
              onClick={() => handleTabClick(3)}
            >
              {t('faq.officeCleaning')}
            </p>
            <p
              className={`cursor-pointer ${
                selectedTab === 4 ? "text-black" : ""
              }`}
              onClick={() => handleTabClick(4)}
            >
              {t('faq.billing')}
            </p>
          </div>
          {/* mini */}
          <div className="relative lg:hidden">
            <select value={selectedOption} onChange={handleOptionChange}>
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className=" flex flex-col pt-12 pb-52 font-helvetica-neue px-4 lg:w-[900px] md:w-[700px]">
          <FAQs />
        </div>
      </div>
      <div className="-mt-32">
        <Footer />
      </div>
    </div>
  );
}
