import Cleaning from "../assests/images/pricingImages/cleaning.png";
import HouseCleaning from "../assests/images/pricingImages/house-cleaning.png";
import House from "../assests/images/pricingImages/house.png";
import RealEstate from "../assests/images/pricingImages/real-estate.png";

export const pricingArr = [
  {
    imgSrc: Cleaning,
    typeKey: "pricing.basicCleaning",
    remarkKey: "pricing.descriptionBasic",
    price: "67",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
    color: "bg-gray-100",
    url: "/pricing/basic-cleaning",
  },
  {
    imgSrc: House,
    typeKey: "pricing.standardCleaning",
    remarkKey: "pricing.descriptionStandard",
    price: "670",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
    color: "bg-teal-100",
    url: "/pricing/deep-cleaning",
  },
  {
    imgSrc: HouseCleaning,
    typeKey: "pricing.advancedCleaning",
    remarkKey: "pricing.descriptionAdvanced",
    price: "800",
    desc1: "Studio Apartment/1 Bedroom: 7EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
    color: "bg-violet-200",
    url: "/pricing/advance-cleaning",
  },
  {
    imgSrc: RealEstate,
    typeKey: "pricing.officeCleaning",
    remarkKey: "pricing.descriptionOffice",
    price: "800",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
    color: "bg-gray-50",
    url: "/pricing/office-cleaning",
  },
];

export const oneTime = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const onceWeek = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const twiceMonth = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const everyMonth = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];

export const others = [
  {
    imgSrc: Cleaning,
    type: "Basic Cleaning",
    price: "30",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: House,
    type: "Deep Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: HouseCleaning,
    type: "Advance Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
  {
    imgSrc: RealEstate,
    type: "Office Cleaning",
    price: "100",
    desc1: "Studio Apartment/1 Bedroom: 70EUROS",
    desc2: "2 Bedrooms/1 Bathroom: 100EUROS",
    desc3: "3 Bedrooms/2 Bathrooms: 130EUROS",
    desc4: "4 Bedrooms/3 Bathrooms: 160EUROS",
    desc5: "Additional Bathrooms: 25EUROS each",
  },
];
