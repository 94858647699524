import { useEffect, useState } from "react";

const useTokenStatus = () => {
  const [tokenAvailable, setTokenAvailable] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setTokenAvailable(!!token);
  }, []);

  return { tokenAvailable };
};

export default useTokenStatus;
