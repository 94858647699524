import { useEffect } from "react";
import { benefitArr } from "../../constants/benefit";
import BenefitCard from "../benefitCard";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Benefits() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  
  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-10 max-xs:px-4">
      {benefitArr.map((image, index) => (
        <div key={index} data-aos="fade-up">
          <BenefitCard image={image.imgPath} />
        </div>
      ))}
    </div>
  );
}
